export default {
  login: {
    login: "Connexion",
    email: "Courriel",
    mobile: "MOBILE",
    mobile2: "Numéro de portable",
    password: "Mot de passe",
    errMsg1: "Veuillez saisir votre email et votre mot de passe",
    errMsg2: "veuillez entrer le mobile et le mot de passe",
    errMsg3: "Votre compte n'est pas dans cette région",
    errMsg4: "identifiant ou mot de passe incorrect",
    errMsg5: "Mauvais numéro de téléphone ou mot de passe utilisateur",
    errMsg6: "Votre inscription n'est pas complète tant que le paiement n'est pas reçu",
    forgotpw: "Mot de passe oublié ?",
    register: "Inscrivez-vous",
    txt: "Vous n’êtes pas encore membre de l’Imperial Treasure Club ? Découvrez nos ",
    txt_2: "Privilèges des membres",
  },
  header: {
    home: "page de garde",
    eStore: "Boutique en ligne",
    PRivileges: "Privilèges",
    register: "Inscrivez-vous",
    highlights: "points forts",
    faq: "FAQ",
    tnp: "Conditions générales et confidentialité",
    profile: "Profil",
  },
  footer: {
    aboutUs: "À propos de nous",
    eStore: "Boutique en ligne",
    faq: "FAQ",
    tnp: "Conditions générales et confidentialité",
    pr: "Restaurant participant",
    profile: "Profil",
    contactUs: "Contact",
    copyright: "droit d'auteur © 2023 Imperial Treasure Restaurant Group Pte Ltd",
  },
  signUp: {
    signUp: "Inscription des membres",
    title: "S’inscrire en tant que membre de l’Imperial Treasure Club",
    mustFill: '"*" indique les champs obligatoires',
    step1: "Détails du compte",
    step2: "Informations de sécurité",
    step3: "Abonnements",
    step4: "Référence",
    salutation: "Formule d’appel",
    salutation_opt1: "Veuillez sélectionner votre titre",
    salutation_opt2: "M.",
    salutation_opt3: "Mme",
    salutation_opt4: "Mme",
    salutation_opt5: "Mlle",
    salutation_opt6: "Dr",
    salutation_errMsg: "Veuillez sélectionner un titre",
    firstname: "Prénom",
    firstname_errMsg: "Veuillez indiquer votre prénom",
    lastname: "Nom",
    lastname_errMsg: "Veuillez indiquer votre nom",
    birth: "Date de naissance",
    birth_errMsg: "Veuillez sélectionner la date de naissance",
    birth_errMsg2: "Vous devez avoir 18 ans ou plus",
    mobile: "Numéro de portable",
    mobile_errMsg: "Veuillez entrer un téléphone valide",
    mobile_errMsg2: "Téléphone déjà enregistré",
    emailAddress: "Adresse courriel",
    emailAddress_errMsg: "Veuillez saisir une adresse e-mail valide",
    emailAddress_errMsg2: "E-mail déjà enregistré",
    confirmEmailAddress: "Confirmer l’adresse courriel",
    confirmEmailAddress_errMsg: "Confirmer l’adresse courriel",
    gender: "Sexe",
    gender_opt1: "Veuillez sélectionner le sexe",
    gender_opt2: "Mâle",
    gender_opt3: "femme",
    gender_errMsg: "Veuillez sélectionner le sexe",
    language_preference: "Préférence de langue",
    language_preference_opt1: "Veuillez sélectionner votre langue de préférence",
    language_preference_opt2: "English",
    language_preference_opt3: "Français",
    language_preference_errMsg: "Veuillez sélectionner votre langue de préférence",
    password_format: "Le mot de passe doit contenir au moins 8 caractères alphanumériques et au moins une majuscule et un caractère spécial (?!@$%&*)",
    password: "*Mot de passe",
    password_errMsg: "Veuillez entrer le mot de passe",
    confirm_password: "*Confirmez le mot de passe",
    confirm_password_errMsg: "Saisissez à nouveau le mot de passe",
    step3_consent_opt: "Je reconnais avoir lu et accepté les <link>conditions générales<link> ci-inclus.",
    step3_consent_txt: "Veuillez consulter notre <link>avis de confidentialité<link> pour plus d’informations sur la manière dont Imperial Treasure traitera vos données personnelles.",
    tnc_errMsg: "Veuillez accepter les conditions générales pour passer à l'étape suivante",
    step3_title: "En cochant la ou les cases ci-dessous, je donne mon consentement à Imperial Treasure (France) (« ITRG ») pour collecter, utiliser, stocker et/ou divulguer toute donnée personnelle fournie par moi dans le cadre de l’adhésion à l’Imperial Treasure Club (« Données »). ITRG pourra également partager mes données avec l’une de ses sociétés associées ou apparentées ou d’autres tiers afin que je puisse profiter des avantages découlant de mon adhésion à l’Imperial Treasure Club, y compris les avantages découlant de la relation d’ITRG avec des prestataires de services en France et à l’étranger. Vous pouvez retirer votre consentement à tout moment.",
    step3_q: "Je souhaite recevoir des messages marketing et promotionnels de la part d’Imperial Treasure (France).",
    step3_q_opt1: "Par courriel",
    step3_q_opt2: "Par téléphone",
    step4_q_err: "Veuillez sélectionner Où avez-vous entendu parler de notre programme",
    step4_q: "Où avez-vous entendu parler de notre programme ?",
    step4_q_opt1: "Sélectionnez une référence",
    step4_q_opt2: "Imperial Treasure Fine Chinese Cuisine",
    step4_q_opt3: "Bouche à oreille",
    step4_q_opt4: "Médias sociaux",
    step4_q_opt5: "Autres",
    promo_code: "Code promo",
    promo_code_input_msg: "Veuillez saisir un code promotionnel valide (le cas échéant)",
    promo_code_err_msg: "veuillez entrer un code promotionnel valide",
    promo_code_re_msg: "Le code promotionnel est valide!",
    total: "Total:",
    refresh: "Rafraîchir",
    captcha: "Captcha",
    captcha_errMsg: "Captcha incorrect",
    cancel: "Annuler",
    next: "Suivante",
    back: "Précédent",
    submitForm: "Procéder au paiement",
  },
  aboutUs: {
    aboutUs: "À propos de nous",
    txt1: "The Imperial Treasure Restaurant Group",
    txt2: "Avec notre vaste variété de spécialités chinoises emblématiques et notre engagement à fournir un service impeccable, Imperial Treasure est devenu synonyme de cuisine chinoise raffinée et authentique. Depuis la création de notre premier restaurant en 2004, le groupe de restaurants Imperial Treasure a conquis le cœur des fins gourmets et des convives occasionnels.",
    txt3: "Le groupe compte aujourd'hui 20 restaurants et plus de 50 distinctions - dont deux étoiles au Guide Michelin de Shanghai (2017-2023), deux étoiles au Guide Michelin de Guangzhou (2020-2022), une étoile au Guide au Guide Michelin de Guangzhou (2019), une étoile au Guide Michelin de Singapour (2017- 2019, 2021 - 2022) , une étoile au Guide Michelin de Hong Kong Macao (2018-2022), deux étoiles au Guide Michelin de Guangzhou (2021-2022) Assiette Michelin au Guide Michelin de Guangzhou (2018, 2020), Assiette Michelin au Guide Michelin de Singapour (2018,2019), Assiette Michelin au Guide Michelin de Londres (2019), Assiette Michelin au Guide Michelin France (2020) deux perles au Guide Black Pearl de Meituan-Dianping (2018) et un classement parmi les 50 meilleurs restaurants d'Asie (2013, 2014, 2015).",
    txt4: "Imperial Treasure possède des restaurants à Singapour, Shanghai, Guangzhou, Hong Kong, Incheon, Londres et Paris.",
  },
  faqs: {
    faqs: "FAQs",
    general: "Généralités",
    general_q1: "Qu’est-ce que l’Imperial Treasure Club ?",
    general_q1_opt1: "L’Imperial Treasure Club est un programme de fidélité sans carte qui permet aux membres de profiter de privilèges et de récompenses exclusifs chaque fois qu’ils dînent dans l’un des restaurants Imperial Treasure participants.",
    general_q2: "Comment puis-je devenir membre de l’Imperial Treasure Club ?",
    general_q2_opt1: "Vous pouvez vous inscrire en ligne à members.imperialtreasure.fr",
    general_q3: "Quel est le restaurant participant à Paris?",
    general_q3_opt1: "Imperial Treasure Fine Chinese Cuisine",
    general_q3_opt1_1: "44 Rue de Bassano, 75008 Paris France | Tel: +33 (0) 1 58 56 29 13",
    general_q4: "Quelle est la validité de l’adhésion?",
    general_q4_opt1: "L’adhésion est valable 1 an à compter de la date d’inscription. Par exemple, si l’inscription est effectuée le 17 août 2024, l’adhésion expirera le 31 août 2025.",
    general_q5: "Où puis-je vérifier la date d’expiration de mon adhésion? ",
    general_q5_opt1: "Vous pouvez vous connecter au portail des membres pour vérifier la date d’expiration de votre adhésion.",
    general_q6: "Quel est l’âge minimum pour pouvoir postuler en tant que membre du Treasure Club Impérial ?",
    general_q6_opt1: "Les clients doivent être âgés de plus de 18 ans pour postuler en tant que membre du Trésor Impérial.",
    general_q7: "Pourquoi devrais-je enregistrer mes coordonnées sur l’Imperial Treasure Club ?",
    general_q7_opt1: "L’enregistrement de vos coordonnées sur https://members.imperialtreasure.fr consiste à activer votre compte. De plus, vous recevrez également les dernières nouvelles pour membres sur les promotions à venir, les récompenses, les invitations à des événements et plus encore!",
    general_q8: "Où puis-je mettre à jour mes données personnelles ?",
    general_q8_opt1: "Pour mettre à jour ou consulter vos données personnelles, veuillez-vous connecter sur votre compte via le portail des membres sur members.imperialtreasure.fr  et cliquer sur « Profil ». Pour obtenir de l’aide pour mettre à jour votre date de naissance, veuillez écrire à fr.club@imperialtreasure.com.",
    general_q9: "Comment puis-je vérifier mes i-dollars, vouchers et autres récompenses?",
    general_q9_opt1: "Vous pouvez vous connecter à members.imperialtreasure.fr pour vérifier vos privilèges de membre et votre solde de récompenses.",
    general_q10: "Que faire si j’ai oublié mon mot de passe ?",
    general_q10_opt1: "Pour réinitialiser votre mot de passe, allez sur le portail des membres sur members.imperialtreasure.fr et cliquez sur « Mot de passe oublié ». Suivez les instructions à l’écran pour réinitialiser votre mot de passe.",
    general_q11: "Que dois-je faire si j’ai des questions concernant mon compte de membre?",
    general_q11_opt1: "Si vous avez des questions concernant votre compte de membre, veuillez nous envoyer un courriel à fr.club@imperialtreasure.com.",
    general_q12: "Mon adhésion au Treasure Club Impérial est-elle transférable ?",
    general_q12_opt1: "Non. L’adhésion n’est pas transférable.",
    general_q13: "Comment puis-je résilier mon adhésion?",
    general_q13_opt1: "Le membre peut choisir de mettre fin à son adhésion à tout moment avant la date d’expiration en écrivant à fr.club@imperialtreasure.com.  Veuillez noter qu’il n’y aura pas de remboursement et/ou d’émission anticipée de bons électroniques inutilisés au moment de la résiliation. Autrement, votre adhésion expirera automatiquement au non-renouvellement de l’adhésion, et tous les i-dollars soldes et / ou les bons électroniques non utilisés seront considérés comme perdus.",
    membershipTypes: "Niveaux d’adhésion",
    membershipTypes_q1: "Quels sont les niveaux d’adhésion ?",
    membershipTypes_q1_opt1: "Il y a 3 niveaux d’adhésion - Argent, Or et Diamant.",
    membershipTypes_q2: "Comment être membre Argent / Or / Diamant?",
    membershipTypes_q2_opt1: "Pour devenir membre Argent, il vous faut une invitation et inscription en ligne.",
    membershipTypes_q2_opt2: "Pour devenir membre Or, les membres doivent atteindre le palier de 8888€* et plus dépensés au cours de l’année d’adhésion.",
    membershipTypes_q2_opt3: "Pour devenir membre Diamant, les membres doivent atteindre le palier de 23888€* et plus dépensés au cours de l’année d’adhésion.",
    membershipTypes_q2_opt4: "*Le montant dépensé est calculé avant les frais de service (le cas échéant), la TVA (le cas échéant), et autres termes et conditions s'appliquent.",
    membershipTypes_q3: "Comment conserver mon statut de membre ?",
    membershipTypes_q3_opt1: "Pour conserver votre statut de membre Or, il vous suffit d’atteindre le palier de 8888€* dépensés au cours de l’année.",
    membershipTypes_q3_opt2: "Pour conserver votre statut de membre Diamant, il vous suffit d’atteindre le palier de 23888€* dépensés au cours de l’année.",
    membershipTypes_q3_opt3: "*Le montant dépensé est calculé avant les frais de service (le cas échéant), la TVA (le cas échéant), et autres termes et conditions s'appliquent.",
    membership: "Privilèges d’adhésion",
    membership_q1: "Quels sont les privilèges d’adhésion ?",
    type_of_privileges: "Mets et boissons offerts",
    sliver: "Argent",
    gold: "Or",
    diamond: "Diamant",
    complimentary_treats: "Des mets offerts",
    complimentary_treats_1_1: "1 x Canard Laqué",
    complimentary_treats_1_2: "-",
    complimentary_treats_1_3: "Oui",
    complimentary_treats_1_4: "Oui",
    complimentary_treats_2_1: "1 x Boite de Gâteaux de Lune (Saisonnier)",
    complimentary_treats_2_2: "-",
    complimentary_treats_2_3: "Oui",
    complimentary_treats_2_4: "Oui",
    complimentary_treats_3_1: "1 x Bouteille de Vin Rouge",
    complimentary_treats_3_2: "-",
    complimentary_treats_3_3: "-",
    complimentary_treats_3_4: "Oui",
    complimentary_treats_4_1: "1 x Bouteille de Champagne",
    complimentary_treats_4_2: "-",
    complimentary_treats_4_3: "-",
    complimentary_treats_4_4: "Oui",
    complimentary_treats_5_1: "1 x Langouste Rose",
    complimentary_treats_5_2: "-",
    complimentary_treats_5_3: "-",
    complimentary_treats_5_4: "Oui",
    complimentary_treats_6_1: "1 x Bon Électronique de 300€",
    complimentary_treats_6_2: "-",
    complimentary_treats_6_3: "-",
    complimentary_treats_6_4: "Oui",
    membership_q3: "Comment puis-je connaître l’expiration de mes privilèges?",
    membership_q3_opt1: "Pour vérifier les dates d’expiration de vos privilèges, il vous suffit de vous connecter au portail des membres members.imperialtreasure.fr et de consulter les détails dans la section « Vouchers ».",
    membership_q4: "Comment puis-je m’identifier en tant que membre du Imperial Treasure Club ?",
    membership_q4_opt1: "Pour vous identifier en tant que membre, il vous suffit de fournir votre numéro de téléphone portable à notre personnel de service.",
    membership_q5: "Comment puis-je profiter de mes gratuités ?",
    membership_q5_opt1: "Pour l’échange de produits alimentaires / vins, une commande à l’avance de (01) jour est requise et disponible chez Imperial Treasure Fine Chinese Cuisine.  Pour l’échange de bons électroniques de 300€, il suffit d’informer le personnel de service avant le paiement.  Le membre doit être physiquement présent (preuve d’identité requise) pour bénéficier des privilèges d’adhésion.",
    membership_q6: "Puis-je, en tant que client d’entreprise, bénéficier d’une réduction d’entreprise avec l’adhésion à l’Imperial Treasure Club?",
    membership_q6_opt1: "Non, la réduction d’entreprise n’est pas cumulable avec l’adhésion à l’Imperial Treasure Club. Les clients d’entreprise ne peuvent choisir que la remise d’entreprise ou l’adhésion à l’Imperial Treasure Club pour chaque notes.",
    membership_q7: "Puis-je profiter de mes privilèges d’adhésion au Imperial Treasure Club dans un autre pays où il y a un restaurant Imperial Treasure ? ",
    membership_q7_opt1: "Vous pouvez gagner des i$ dans tous les restaurants Imperial Treasure, quel que soit l’emplacement. La  conversion i$ sera basée sur le pays de base enregistré et selon le taux de change établi par Imperial Treasure. Vous ne pourrez cependant pas utiliser vos i$ et bons électroniques or du pays où vous avez enregistré votre compte.",
    membership_q8: "Mon adhésion au Treasure Club Impérial est-elle transférable ? ",
    membership_q8_opt1: "Non. L’adhésion n’est pas transférable.",
    earning: "Gain et rachat de points",
    earning_q1: "Comment puis-je gagner des i$?",
    earning_q1_opt1: "Gagnez 1 i$ pour chaque tranche de 10 €* dépensés dans les restaurants participants (*exclut les frais de service, les taxes gouvernementales en vigueur, les bons électroniques et l’achat ou l’utilisation de bons de restauration et d’articles festifs Imperial Treasure).  Le membre doit être physiquement présent (preuve d’identité requise) au moment du repas. Les demandes d’accumulation de i$ pour des reçus antérieurs ou antidatés ne seront pas acceptées. Le système peut nécessiter jusqu’à un (01) jour ouvrable pour refléter les transactions, les bons électroniques et i$ gagnés. La combinaison de plusieurs comptes d’adhésion ou le fractionnement de la facture pour gagner i$ est strictement interdit. Le solde accumulé expire à l’expiration de l’adhésion.",
    earning_q2: "Comment puis-je échanger mon i$?",
    earning_q2_opt1: "Pour chaque i$50 gagné, les membres recevront automatiquement un voucher* de 50€ valable pour une utilisation au cours de l’année d’adhésion. Le voucher de 50€ peut être utilisé pour régler votre note dans l’un des restaurants participants. Le membre doit être physiquement présent (preuve d’identité requise) au moment du repas pour pouvoir utiliser les privilèges d’adhésion. Le membre est tenu de signer le reçu pour accuser réception du ou des échanges effectués. Il est interdit de combiner plusieurs comptes d’adhésion ou de diviser la facture pour racheter les privilèges d’adhésion.\n(*Le système convertit automatiquement i$ à une certaine heure tous les jours)",
    earning_q3: "Puis-je gagner des i$ ou gagner des privilèges d’adhésion pour des achats en ligne effectués à la boutique en ligne?",
    earning_q3_opt1: "Non. L’obtention de i$ et/ou les privilèges d’adhésion ne sont disponibles que pour les achats effectués dans les restaurants participants, sauf indication contraire.",
    earning_q4: "Mon i$ peut-il expirer?",
    earning_q4_opt1: "Oui, les i$ gagnés ne sont valides que pendant la période d’adhésion et expirera une fois l’adhésion expirée. Le solde d’i$ sera avancé pour une période de grâce de trois (03) mois à l’expiration de l’adhésion et doit être converti en un voucher de voucher de 50€ dans le délai de grâce. Il sera sinon perdu.",
    earning_q5: "Mon i$ sera-t-il mise à jour immédiatement une fois que j’aurai effectué un achat?",
    earning_q5_opt1: "Oui. Le système mettra à jour l’i$ gagné instantanément, mais des problèmes de réseau et autre causes extérieurs peuvent créer des retards.",
    earning_q6: "Puis-je utiliser plusieurs comptes d’adhésion pour gagner des i$ et échanger des privilèges d’adhésion ?",
    earning_q6_opt1: "Non. Il n’est pas permis de combiner plusieurs comptes d’adhésion pour gagner des i$ ou échanger des privilèges d’adhésion.",
    earning_q7: "Puis-je fractionner ma facture pour gagner des i$ et/ou échanger des privilèges d’adhésion ?",
    earning_q7_opt1: "Non. Le fractionnement de facture pour gagner des i$ et/ou racheter des privilèges d’adhésion n’est pas autorisé.",
    earning_q8: "Ma famille et mes amis peuvent-ils gagner des i$ ou échanger des privilèges d’adhésion si je ne suis pas présent?",
    earning_q8_opt1: "Non. Les membres doivent être présents pour bénéficier des privilèges d’adhésion.",
    earning_q9: "Puis-je gagner des i$ ou échanger des privilèges d’adhésion pour des commandes effectuées via des commandes en ligne effectuées sur des plateformes tierces?",
    earning_q9_opt1: "Non. L’obtention de i$ et l’échange de privilèges d’adhésion ne sont pas comptabilisés pour les commandes en ligne effectuées via des plateformes tierces.",
  },
  tnc: {
    title: "Terms And Privacy",
    consent: "Consent wording on sign-up page",
    consent_1_1: "□ I acknowledge that I have read and agree to the Terms and Conditions [link: Members.imperialtreasure.fr/tnc] set forth herein.",
    consent_1_2: "Please look at our Privacy Notice for information about how Imperial Treasure will process your personal data [link: Members.imperialtreasure.fr/tnc]”.",
    consent_opt: "Opt-in Wording on sign-up page",
    consent_2_1: "By checking the box(es) below, I give my consent to Imperial Treasure (France) (“ITRG”) to collect, use, store and/or disclose any personal data provided by me in connection with the Imperial Treasure Club membership (“Data”) for ITRG to share my Data with any of its associated or related companies or other third parties so that I may enjoy the benefits arising from my membership of the Imperial Treasure Club, including benefits arising from ITRG’s relationship with service providers within and outside of France. You can withdraw your consent at any time.",
    consent_2_2: "I would like to receive marketing and promotional messages and materials from Imperial Treasure (France).",
    consent_2_3: "□ By Email    □ By Post     □ By Mobile",
    membership: "Membership Terms And Privacy",
    membership_1_1: "1.1 Introduction",
    membership_1_1_1: 'Imperial Treasure (France) (“Imperial Treasure”) would like to offer our privileged customers news and information regarding our products and services, including promotions, special offers and discounts.  We are offering you the opportunity to become a member of the Imperial Treasure Club (a "Member") to enjoy special privileges that Imperial Treasure may offer from time to time.',
    membership_1_2: "1.2 Collection and use of personal data",
    membership_1_2_1: "If you become a Member, you will need to provide Imperial Treasure with certain personal information (including your name and contact details such as email address and phone number).  You will need to provide us with your mobile number for us to identify you. Your mobile number will be your username or unique identifier as a member of Imperial Treasure Club. Any personal data held by us about you will be held in accordance with our Privacy Notice.",
    membership_1_3: "1.3 Direct marketing",
    membership_1_3_1: 'By clicking on the "SUBMIT" button below, you agree that Imperial Treasure, our affiliates, related entities, and third parties acting on our behalf ("Business Partners"), may collect, use and disclose your personal data, which you have provided at the member\'s portal at https://members.imperialtreasure.fr (the "Member\'s Portal"), for providing marketing material that you have agreed to receive, in accordance with the EU General Data Protection Regulation (“GDPR”) and our Privacy Notice. Please note that marketing material may be sent directly by our Business Partners, and we may disclose your personal data to them for that purpose.',
    membership_1_3_2: 'As a consumer, you have the right to register on the telephone direct marketing opt-out list “Bloctel”. Bloctel is the telephone marketing opt-out list on which any consumer can register free of charge to no longer be contacted by phone by a professional with whom they have no current contractual relationship, in accordance with the French consumer law provisions (Article L. 223-22 of the French Consumer Code). Under French law, it is prohibited for any professional, directly or through a third party acting on his behalf, to phone a consumer registered on the Bloctel list, without any exemptions provided by the law.',
    membership_1_4: "1.4 Membership Tiers",
    membership_1_4_1: "1.4.1 Membership registration, i-dollars, and Cash e-Vouchers",
    membership_1_4_1_1: "Where you sign up to become a Member, you will be required to provide your mobile number, among other information we may require from you, in order to sign up for the membership. This may be required online or by our restaurant staff depending on the circumstances.",
    membership_1_4_1_2: "Membership is strictly non-transferable and shall only be enjoyed by the Member in person.",
    membership_1_4_1_3: "As a Member, you earn one (01) i-dollar at the rate specified on the membership programme website [https://members.imperialtreasure.fr] for the country in which you signed up, when you spend at any participating Imperial Treasure restaurants (excluding service charge, VAT, Cash e-vouchers used, and the purchase and use of Imperial Treasure gift vouchers). Please refer to the Member’s Portal for a list of participating Imperial Treasure restaurants. Member must be physically present at the point of dining and provide the registered mobile number to the service staff in order to earn i-dollars. Earning of i-dollars must be made at the point of transaction. Requests on i-dollar accumulation for previous or backdated receipts will not be entertained. The system may require up to one (01) working day to reflect the transactions, Cash e-vouchers and i-dollar earned. I-dollars accrued shall expire upon the expiry of membership. Any i-dollar accrued is however granted a three (03) months grace period and must be converted to Cash e-Vouchers within this grace period, failing which such i-dollar shall be deemed forfeited. Transactions made via third party vendors are not eligible for earning of i-dollars.",
    membership_1_4_1_4: "Earned i-dollars are converted in the Member Portal into Imperial Treasure e-vouchers of a specified value (“Cash e-Vouchers”) at the rate specified on the membership programme website [https://members.imperialtreasure.fr] for the country in which you signed up. Each Cash e-Voucher is valid for twelve (12) months from date of issue and valid for use within the membership period only. The Cash e-Voucher can be used to offset the bill when dining at any participating Imperial Treasure restaurants and any other terms and conditions stated at [https://members.imperialtreasure.fr]. Neither i-dollars nor Cash e-Vouchers have any cash value and cannot be converted or redeemed for cash.",
    membership_1_4_2: "1.4.2 Membership Tiers",
    membership_1_4_2_1: "Unless otherwise determined by Imperial Treasure, Silver Tier status is conferred on Members when they are invited to first join the membership programme and is valid for the period specified on the membership programme website [https://members.imperialtreasure.fr] for the country in which you signed up. At the end of this period, if the Member has not been upgraded to the Gold Tier or Diamond Tier, the Member may be required to pay such renewal fee or meet the relevant renewal requirements as may be prescribed by Imperial Treasure on the membership website [link] for the country in which you signed up.",
    membership_1_4_2_2: "Gold Tier status is conferred on Members who spend at least the amount set out for Gold Tier on the membership programme website [https://members.imperialtreasure.fr] for the country in which you signed up, at participating Imperial Treasure restaurants within twelve (12) months. Gold Tier status is valid for twelve (12) months from the date the Member qualifies; after that, annual requalification is required. Upon expiration of your membership tenure as a Gold Tier member, and if you have not met the minimum spend requirement to be retained in the tier, you shall be downgraded to Silver Tier status.",
    membership_1_4_2_3: "Diamond Tier status is conferred on Members who spend at least the amount set out for Diamond Tier on the membership programme website [https://members.imperialtreasure.fr] for the country in which you signed up, at participating Imperial Treasure restaurants within twelve (12) months. Diamond Tier status is valid for twelve (12) months from the date the Member qualifies; after that, annual requalification is required. Upon expiration of your membership tenure as a Diamond Tier member, and if you have not met the minimum spend requirement to be retained in the tier, you shall be downgraded to Gold Tier or Silver Tier status accordingly.",
    membership_1_4_2_4: "Imperial Treasure may alter, limit, or modify the Silver Tier, Gold Tier and/or Diamond Tier programme rules, regulations, benefits, eligibility for membership, or any other feature of the Silver Tier, Gold Tier and/or Diamond Tier programme and/or may terminate the Silver Tier, Gold Tier and/or Diamond Tier programme at any time, by giving reasonable notice on the membership programme website and/or by email. Please check the membership programme website regularly for updates.",
    membership_1_4_3: "1.4.3 Membership Tier Rewards",
    membership_1_4_3_1: "Each membership tier also entitles Members to certain rewards and privileges, which may include other e-vouchers, free gifts and/or other dining benefits (“Rewards”). The current tier privileges are as set out in the membership programme website [https://members.imperialtreasure.fr]. The validity and terms and conditions of such Rewards are as set out in [https://members.imperialtreasure.fr], which may be amended by Imperial Treasure by giving reasonable notice by email. Rewards are always subject to availability. Imperial Treasure reserves the right to replace a Reward with another Reward of equivalent or similar value. Rewards do not have any cash value and cannot be converted or redeemed for cash.",
    membership_1_4_4: "1.4.4 Redemption of Rewards and Cash e-Vouchers",
    membership_1_4_4_1: "Member must be physically present (proof of identification required) at the point of dining in order to redeem any membership privileges. Member is required to sign off on the receipt to acknowledge the redemption(s) made. Redemption of Rewards are subject to prevailing local law requirements.",
    membership_1_4_4_2: "Combining multiple membership accounts, combining bills or splitting the bills to earn i-dollars and/or redeem Rewards and membership privileges is strictly not allowed. Membership must be active at the point of redemption.",
    membership_1_5: "1.5 Amending personal data and terminating membership",
    membership_1_5_1: "You may amend your personal data or marketing preferences at any time via our Member’s Portal. You will need to inform us in writing at fr.club@imperialtreasure.com should you choose to terminate your membership. Please allow a few working days for changes to take effect. Kindly be advised that there will be no refunds made and/or early issuance of any unused Cash e-vouchers at the point of termination. Alternatively, your membership will automatically expire upon non-renewal of membership, and all balance i-dollars and/or unused Cash e-vouchers will be deemed forfeited.",
    membership_1_6: "1.6 Termination and cancellation",
    membership_1_6_1: "We reserve the right to terminate the Member's membership if the membership account is found to be shared with another non-Member. \nWe reserve the right to terminate your membership, in our absolute discretion, should you abuse any of the conditions and privileges bestowed on you as a Member.",
    membership_2: "2. Law and forum",
    membership_2_1: "These Terms, including all policies and additional terms referenced herein (“Membership Agreement”),  are governed by and construed in accordance with the laws of Singapore. If you are a consumer and we direct our services and/or offers in the country in which you are resident, you will benefit from any mandatory provisions of the law of the country in which you are resident.  Nothing in this Membership Agreement affects your rights as a consumer or your other statutory rights to rely on such mandatory provisions of any regulations.",
    membership_2_2: "Any dispute or claim arising out of or in connection with this Membership Agreement, (including non-contractual disputes or claims) shall be subject to the exclusive jurisdiction of Singapore courts. If you are a consumer, you may bring any dispute which may arise under this Membership Agreement to the competent court of your country of habitual residence if this country of habitual residence is within the EEA.",
    privacy: "Privacy Notice",
    privacy_1: "1. INTRODUCTION",
    privacy_1_1: 'Imperial Treasure Restaurant Group Pte Ltd, Imperial Treasure Cantonese Cuisine Pte Ltd, Imperial Treasure Noodle House Pte Ltd, Imperial Treasure Fine Dining Restaurant Pte Ltd, Multirich F&B Management Pte Ltd, Imperial Treasure Windows of Hong Kong Pte Ltd and Imperial Treasure Steamboat Pte Ltd (collectively referred as "Imperial Treasure", "us", "we") is committed to protect the privacy of visitors to this website (the "Website"). This Privacy Notice sets out how Imperial Treasure collects, uses or discloses personal data that you have provided to us through the Website.',
    privacy_1_2: "The controller according to the EU General Data Protection Regulation (hereinafter, the “GDPR”) is: \n\n Name of the Data Controller: IT France \n\n Tel.: +33 (0) 1 58 56 29 13 \n\nE-Mail: fr.marketing@imperialtreasure.com \n\nWebsite: www.imperialtreasure.com/france/",
    privacy_1_3: 'This Privacy Notice does not apply to other websites to which we link ("Third Party Website" or "Third Party Websites").',
    privacy_1_4: "This Privacy Notice also describes your data protection rights, including a right to object to some of the processing which Imperial Treasure carries out. More information about your rights, and how to exercise them, is set out in the following section: “9. What rights do I have”.",
    privacy_1_5: 'Maintaining the privacy of our customers’ information is important to us. We do not disclose rent or sell our customers’ personal data to third parties (except as described in "6. Disclosures of personal data to third parties").',
    privacy_2: "2. COLLECTION AND USE OF PERSONAL DATA",
    privacy_2_1: "We collect the following personal data when you register as a user of the Website and make purchases through the Website:",
    privacy_2_1_1: "email address",
    privacy_2_1_2: "your name, address and phone number (including delivery addresses if different);",
    privacy_2_1_3: "billing address, including your name, address and phone number; and",
    privacy_2_1_4: "any other personal data provided to us when you submit any comment, question or feedback to us, request services from us, or disclose your personal data to us in any way",
    privacy_2_2: "We use your personal data for the following purposes and on the following legal basis:",
    privacy_2_2_1: "To fulfil a contract, or take steps linked to a contract: this is relevant where you make a purchase from us. This includes:",
    privacy_2_2_1_1: "process your application as a Member and provide you with the membership privileges;",
    privacy_2_2_1_2: "fulfil your order and deliver it to you;",
    privacy_2_2_1_3: "facilitate and track your purchases from us (including in our restaurants or via our websites); and",
    privacy_2_2_1_4: "provide you with service support;",
    privacy_2_2_2: "To serve our legitimate interest in conducting and improving its business, offering goods and services and preventing fraud, for the following purposes:",
    privacy_2_2_2_1: "to facilitate your registration as a Member;",
    privacy_2_2_2_2: "to use information you provide to provide products and services you have requested;",
    privacy_2_2_2_3: "(if you use a card payment for any purchase on the Website) we use third parties to check the validity of the sort code, account number and card number you submit in order to prevent fraud; and",
    privacy_2_2_2_4: "to respond to comments, queries and feedback.",
    privacy_2_2_3: "Where you give us consent:",
    privacy_2_2_3_1: "we provide you with marketing and promotional material and other information about our products (including promotions and special offers), as well as other products and services provided by our affiliates and partners and",
    privacy_2_2_3_2: "on other occasions where we ask you for consent, we will use the data for the purposes which we explain at that time.",
    privacy_2_2_4: "To comply with a legal obligation to which we are subject, for purposes which are required by law, or in response to requests by government or law enforcement authorities conducting an investigation.",
    privacy_2_3: "The provision of your name, family name, contact number,  country code and email address are required so that we can contact you and fulfil any of your order(s): if required data is not provided, then we will not be able to fulfil your order(s). All other provision of your information is optional. In any cases, when the collection of your personal data is required to execute a contractual obligation we have with you or to comply with our legal obligation, we will inform you thereof at the time of collection, via an asterisk.",
    privacy_3: "3. COOKIES AND SIMILAR TECHNOLOGIES",
    privacy_3_1: "Additionally, through the Website, we may gather certain information about the use of the Website, such as how frequently certain areas of the Website are visited, including through the use of cookies, web beacons, and other technologies.",
    privacy_3_1_1: "What are cookies?",
    privacy_3_1_1_1: "A cookie is a small text document, which often includes an anonymous unique identifier. Cookies are created when your browser loads a particular website. The website sends information to the browser which then creates a text file. Every time the user goes back to the same website, the browser retrieves and sends this file to the website's server. Find out more about the use of cookies on www.allaboutcookies.org.",
    privacy_3_1_1_2: "We also use other forms of technology (such as web beacons and, in apps, software development kits (usually referred to as SDKs)) which serve a similar purpose to cookies, and which allow us to monitor and improve our Website and email communications. When we talk about cookies in this Privacy Notice, this term includes these similar technologies.",
    privacy_3_1_2: "What cookies do we use and what information do they collect?",
    privacy_3_1_2_1: "We may use cookies, for the following purposes:",
    privacy_3_1_3: "Required cookies:",
    privacy_3_1_3_1: "These cookies are required to enable core functionality. Without these cookies, services you have asked for, like adding items to your shopping basket, cannot be provided. If you disable these cookies, certain parts of the Website will not function for you. For example, adding items to your basket, proceeding to checkout, identifying the causes of problems arising at web servers and resolving these problems. The processing of personal data using required cookies which are strictly necessary for the provision of services you have requested is based on Art. 6 (1) (b) GDPR.",
    privacy_3_1_4: "Functional cookies:",
    privacy_3_1_4_1: 'We may use cookies that are not essential but enable various helpful features on our Website. For example, these cookies collect information about your interaction with services provided on the Website and may be used on our Website to remember your preferences (such as your language preference), your interests and the presentation of the Website (such as the font size). For functional cookies, depending on the described purposes of use, the legal basis for the processing of personal data using cookies and other technologies either lies in Art. 6 (1) (f) GDPR, or if you have given us your consent to the use of cookies through the "cookie banner" displayed on the website, on Art. 6 (1) (a) GDPR.',
    privacy_3_1_5: "Advertising cookies:",
    privacy_3_1_5_1: 'We use these cookies to collect information about your browsing habits in order: to customize the content of the Website and our emails to suit your individual interests or purposes and selectively display best-matched pages to you, including tracking your past purchases in order to provide you with new services or special promotions. For advertising cookies, the legal basis for the processing of personal data using cookies and other technologies either lies in Art. 6 (1) (f) GDPR, or if you have given us your consent to the use of cookies through the "cookie banner" displayed on the website, on Art. 6 (1) (a) GDPR.',
    privacy_3_1_6: "Analytics cookies:",
    privacy_3_1_6_1: 'These cookies help us improve or optimise the experience we provide. They allow us to measure how visitors interact with the Website and we use this information to improve the user experience and performance of the Website. These cookies are used to: utilize your browsing history on the Website for demographic research; and obtain statistic data of web visitors and aggregated website visitation statistics which are anonymous in nature (such as the last visited Website, the number of pages visited, whether or not email communications are opened, which parts of our website or email communication are clicked on and the length of time between clicks). For analytics cookies, the legal basis for the processing of personal data lies in Art. 6 (1) (a) GDPR if you have given us your consent to the use of cookies through the "cookie banner" displayed on the website.',
    privacy_3_1_7: "Social media cookies:",
    privacy_3_1_7_1: 'These cookies are used when you engage with our content on or through a social site such as Facebook or Instagram. These cookies collect information about your social media interaction with the Website, such as whether you have an account with the social media site and whether you are logged into it when you interact with content on the Website. This information may be linked to targeting/advertising activities. For social media cookies, the legal basis for the processing of personal data lies in Art. 6 (1) (a) GDPR if you have given us your consent to the use of cookies for such purposes through the "cookie banner" displayed on the website.',
    privacy_3_1_8: "You may access and change your cookie preferences at any time by clicking [www.imperialtreasure.com/france/].",
    privacy_3_2: "Third parties",
    privacy_3_2_1: "Your use of the Website may result in some cookies being stored that are not controlled by us. This may occur when the part of the Website you are visiting makes use of third party analytics or marketing automation/management tool or includes content displayed from a third party website, for example, Facebook. You should review the privacy and cookie policies of these services to find out how these third parties use cookies and whether your personal data will be transferred to a third country. A list of the third parties who place cookies on the Website can be found here.",
    privacy_3_3: "How do you manage these technologies?",
    privacy_3_3_1: "We keep information collected from cookies for [a maximum of twenty (24) months.",
    privacy_4: "4. SENDING OF EMAILS TO MEMBERS",
    privacy_4_1: "Genuine emails from Imperial Treasure always will come from an address ending @imperialtreasure.com. Do not open any attachments or click any links from suspicious emails. If you wish to report a suspicious email claiming to be from Imperial Treasure that you believe is a forgery, you may email to fr.marketing@imperialtreasure.com.",
    privacy_5: "5. RELYING ON OUR LEGITIMATE INTERESTS",
    privacy_5_1: "We have carried out balancing tests for all the data processing we carry out on the basis of our legitimate interests, which we have described above. You can obtain information on any of our balancing tests by contacting us using the details set out later in this notice.",
    privacy_6: "6. WITHDRAWING CONSENT OR OTHERWISE OBJECTING TO DIRECT MARKETING ",
    privacy_6_1: "Wherever we rely on your consent, you will always be able to withdraw that consent at any time. Consequently, we are no longer allowed to continue the data processing based on this consent for the future, although we may have other legal grounds for processing your data for other purposes, as set out above.",
    privacy_6_2: "In some cases, we can send you direct marketing without your explicit consent, where we rely on our legitimate interests.  You have an absolute right to opt-out of direct marketing, or profiling we carry out for direct marketing, at any time. You can do this by following the instructions in the communication where it is an electronic message, or by contacting us using the details set out below.",
    privacy_7: "7. DISCLOSURES OF PERSONAL DATA TO THIRD PARTIES",
    privacy_7_1: "In order to provide services to you, we may disclose to third parties and/or transfer your personal data out of France, as described in this Privacy Notice:",
    privacy_7_1_1: "We may share your personal data with our affiliated companies for the purposes of: Fulfilling a contract with you, providing customer services and membership services, and facilitating your registration as a user of the Website:",
    privacy_7_1_2: "We may permit certain trusted third party processors to access your personal data in connection with their performance of services to maintain and operate the Website and certain features on the Website. For example, we may use third parties to host the Website; operate various features available on the Website; analyze data; provide search results and links and assist in fulfilling your orders and may share personal data or other information with our parent, subsidiaries, divisions, and affiliates. These third party instructed will use your personal data only as processors by Imperial Treasure and in a manner consistent with this Privacy Notice, and are prohibited from using or disclosing your personal data for any other purpose.",
    privacy_7_1_3: "We may employ a third-party service to collect the above-mentioned information, including personal data, and use their tracking services on our behalf. However, this information is strictly confidential and will not be shared with others. This third-party service will use your personal data only as instructed by Imperial Treasure and in a manner consistent with this Privacy Notice, and is prohibited from using or disclosing your personal data for any other purpose.",
    privacy_7_1_4: "We may disclose personal data as an asset in connection with a proposed or actual merger or sale (including any disclosures made as part of an insolvency or bankruptcy proceeding) involving all or part of our business or as part of a corporate reorganization, stock sale or other change in control.",
    privacy_7_1_5: "We reserve the right to disclose information, including personal data, in order to comply with legal requirements, such as a subpoena, court order, administrative or governmental order, or any other requirement of law, or when we, in our sole discretion, believe it is necessary in order to protect our rights or the rights of others, to prevent harm to persons or property, to fight fraud and credit risk reduction. We may also disclose or transfer personal data as otherwise permitted or required by law.",
    privacy_7_2: "Your personal data may be transferred out of France from time to time and shared with other countries for cross-border membership benefits. Such transfers will be carried out in accordance with the requirements of the GDPR and of the French Data Protection of 6 January 1978.",
    privacy_7_3: 'Where information is transferred outside the EEA, and where this is to a stakeholder or vendor in a country that is not subject to an adequacy decision by the EU Commission, data is adequately protected by EU Commission approved standard contractual clauses, an appropriate Privacy Shield certification or a vendor\'s Processor Binding Corporate Rules.  A copy of the relevant mechanism can be provided for your review on request to our Data Protection Office (please refer to the section below: "12. Contact Us").',
    privacy_8: "8. HOW WE PROTECT YOUR INFORMATION",
    privacy_8_1: "We are committed to protecting the information we receive from you. We adopt reasonable physical, technical and administrative practices to help protect the confidentiality, security and integrity of data, including personal data, stored on our system. We believe the measures we have implemented reduce the likelihood of security problems to a level appropriate to the type of data involved. Although we take reasonable steps to protect the information we receive from you, no computer system is completely secure, and we cannot be held liable for any loss you may suffer for unauthorized access or loss of any data provided to the Website.",
    privacy_9: "9. LINKS TO THIRD PARTY SITES",
    privacy_9_1: "The Website may contain links to third party websites and microsites, including third party payment gateways, whose privacy practices may differ from those of ours. If you submit personal data, including payment information, to any of those websites, that personal data is not subject to this Privacy Notice and will be subject to the third party's privacy practices instead.",
    privacy_9_2: "We therefore encourage you to review the privacy statement of any website you visit. By clicking on or activating such links and leaving the Website, we can no longer exercise control over any data or information which you give to any other entity after leaving the Website, and any access to such other websites is entirely at your own risk.",
    privacy_10: "10. WHAT RIGHTS DO I HAVE",
    privacy_10_1: "You have the right to ask us for a copy of your personal data; to correct, delete or restrict (stop any active) processing of your personal data; and to obtain the personal data you provide to us for a contract or with your consent in a structured, machine-readable format, and to ask us to share (port) this data to another data controller.",
    privacy_10_2: "In addition, you can object to the processing of your personal data in some circumstances (in particular, where we do not have to process the data to meet a contractual or other legal requirement, or where we are using the data for direct marketing).",
    privacy_10_3: "These rights may be limited, for example if fulfilling your request would adversely affect the rights and freedoms of a third party or if you ask us to delete information which we are required by law to keep or have compelling legitimate interests in keeping. Relevant exemptions are included in the applicable laws and regulations. We will inform you of relevant exemptions we rely upon when responding to any request you make.",
    privacy_10_4: "If you are based in the EU and have unresolved concerns, you have the right to complain to an EU data protection authority where you live, work or where you believe a breach may have occurred.",
    privacy_10_5: "You also have the right to define guidelines for the storage, erasure and disclosure of your personal data after your death.",
    privacy_10_6: 'You also have the right to register on an opt-out list from marketing via telephone (pursuant to L. 223-2 of the French Consumer Code).',
    privacy_10_7: 'To exercise any of these rights, or to obtain other information, such as a copy of a legitimate interests balancing test, you can get in touch with us – by contacting our Data Protection Officer (See "12. Contact us" below).  To correct your personal data, you can do so by signing into your user account and updating your personal data. We will only disclose personal data in accordance with an access request to the individual who registered his/her personal data with the Website, and only after confirming that the person requesting access is indeed the registered party.',
    privacy_10_8: "You may also contact our Data Protection Officer to withdraw your consent to our use of your personal data.",
    privacy_10_9: "Please allow one (1) month for your request to be processed.",
    privacy_11: "11. RETENTION OF INFORMATION",
    privacy_11_1: "Where we process registration data, we do this for as long as you are an active user of the Website and for six (6) years after this.",
    privacy_11_2: "Where we process personal data for marketing purposes or with your consent, we will process such data until you ask us to stop. We also keep a record of the fact that you have asked us not to send you direct marketing or not to process your data so that we can respect your request in the future.",
    privacy_11_3: "Where we process personal data in connection with performing a contract, we keep such data for six (6) years from your last interaction with us.",
    privacy_11_4: "If you withdraw your consent to our collection, use or disclosure of your personal data, we may not be able to provide you with services that require such personal data. Please also note that your personal data may still be retained on our database to the extent permitted by law.",
    privacy_12: "12. PRIVACY POLICY CHANGES",
    privacy_12_1: "If we decide to change our Privacy Notice for the Website, we will post the revised Privacy Notice here and, if appropriate, notify you by email. Please check back frequently to see any updates or changes to our Privacy Notice.",
    privacy_13: "13. CONTACT US – DPO CONTACT ",
    privacy_13_1: "To exercise any of your rights, or iIf you have any questions about this Privacy Notice or wish to obtain a copy of the relevant safeguard mechanism for transferring your personal data outside the EEA, please contact our Data Protection Officer at DPO@imperialtreasure.com. You can also raise a concern or lodge a complaint with a data protection authority or other official with jurisdiction.",
  },
  outlets: {
    outlets: "Restaurant participant",
    restaurant_name: "Nom du restaurant",
    address: "Adresse",
    contact: "Contact",
    email: "Adresse courriel",
    outlets_1_1: "Imperial Treasure Fine Chinese Cuisine",
    outlets_1_2: "44 rue de Bassano, Paris, (à l’intérieur de l’hôtel La Clef Champs-Élysées Paris)",
    outlets_1_3: "33 (0) 1 58 56 29 13",
    outlets_1_4: "finechinese_paris@imperialtreasure.com",
  },
  privilege: {
    title: "PRIVILÈGES",
    privilege: "Privilèges des membres",
    privelege_txt: "Gagnez un 1i$ tous les 10€ dépensés dans les restaurants participants.\nAccumulez 50i$ afin de recevoir un bon électronique de 50€ à utiliser dans les restaurants participants.",
    silver: "ARGENT",
    silver_1: "Uniquement sur invitation",
    gold: "OR",
    gold_1: "Passez au niveau OR lorsque vous dépassez 8888€* de consommation et plus au cours de l’année d’adhésion.",    
    gold_2: "Des mets offerts: \n1 x Canard Laqué \n1 x Boîte de Gâteaux de Lune (Saisonier)",
    diamond: "DIAMANT",
    diamond_1: "Passez au niveau DIAMANT lorsque vous dépassez 23888€* de consommation et plus au cours de l’année d’adhésion.",
    diamond_1_1: "Tous les avantages du niveau GOLD plus:",
    diamond_2: "1 x Bon Électronique de 300€",
    diamond_3: "Des mets offerts: \n1 x Bouteille de Champagne \n1 x Bouteille de Vin Rouge \n1 x Langouste Rose",
    service_charge_note: "*N'inclut pas les frais de service (le cas échéant), la TVA, les autres termes et conditions s'appliquent.",
    sign_up_now: "S'inscrire maintenant",
  },
  resetPassword: {
    title: "Mot de passe oublié",
    reset_password: "Réinitialiser le mot de passe",
    email: "*Veuillez saisir un e-mail",
    email_input: "Veuillez saisir un e-mail",
    email_errMsg: "Veuillez saisir une adresse e-mail valide",
    mobile: "*Veuillez entrer mobile",
    mobile_errMsg: "Veuillez entrer un téléphone valide",
    new_password: "*Nouveau mot de passe",
    new_password_errMsg1: "Veuillez entrer le mot de passe",
    new_password_errMsg2: "Le mot de passe doit contenir au moins 8 caractères alphanumériques et au moins une majuscule et un caractère spécial (?!@$%&*)",
    confirm_password: "*Confirmer le nouveau mot de passe",
    confirm_password_errMsg: "Confirmez le mot de passe",
    reset_password2: "Réinitialiser le mot de passe",
    has_been_reset: "Le mot de passe a été réinitialisé!",
    login: "Connexion",
  },
  forgetPassword: {
    title: "Mot de passe oublié",
    reset_password: "Réinitialiser le mot de passe",
    forgot_password: "Mot de passe oublié ?",
    reset_pw_method: "Veuillez sélectionner la méthode de réinitialisation du mot de passe",
    by_mobile: "Par mobile",
    by_email: "Par email",
    email: "*Veuillez saisir un e-mail",
    email_input: "Veuillez saisir un e-mail",
    email_errMsg: "Veuillez saisir une adresse e-mail valide",
    mobile: "*Veuillez entrer mobile",
    mobile_errMsg: "Veuillez entrer un téléphone valide",
    reset_password2: "Réinitialiser le mot de passe",
    reset_password_sent_email: "Le lien de réinitialisation du mot de passe a été envoyé à votre adresse e-mail.",
    reset_password_sent_mobile: "Le lien de réinitialisation du mot de passe a été envoyé sur votre mobile.",
    next: "Suivante",
    back: "Dos",
  },
  updateEmail: {
    title: "Changer votre adresse courriel",
    member_profile: "Profil du membre",
    updateEmail: "Changer votre adresse courriel",
    new_email: "*Nouvelle adresse courriel",
    sent: "Envoyé",
    send_token: "Envoyer le code de vérification",
    email_errMsg1: "Veuillez saisir une adresse e-mail valide",
    email_errMsg2: "E-mail déjà enregistré",
    token: "*le code de vérification",
    token_error: "Erreur de code de vérification",
    update: "Mettre à jour",
    update_success: "Mise à jour réussie!",
    done: "Terminé",
    Msg1: "Le téléphone portable et le courrier électronique sont utilisés",
    Msg2: "Adresse e-Mail déjà utilisée",
  },
  updateMobile: {
    title: "Modifier le numéro de téléphone mobile",
    member_profile: "Profil du membre",
    updateMobile: "Modifier le numéro de téléphone mobile",
    new_mobile: "*Nouveau numéro de portable",
    sent: "Envoyé",
    send_token: "envoyer le code de vérification",
    mobile_errMsg1: "Veuillez entrer un téléphone valide",
    mobile_errMsg2: "Téléphone déjà enregistré",
    token: "*jeton",
    token_error: "erreur de jeton",
    update: "Mettre à jour",
    update_success: "Mise à jour réussie!",
    done: "Terminé",
    Msg1: "Le téléphone portable et le courrier électronique sont utilisés",
    Msg2: "Adresse e-Mail déjà utilisée",
  },
  coupon: {
    title: "Bons d’achat",
    member_profile: "Espace membre",
    coupon: "Bons d’achat",
    active: "Actif",
    redeemed: "Remboursé",
    expired: "Expiré",
    type_of_vouchers_opt1: "Veuillez sélectionner le type de coupon",
    type_of_vouchers_opt2: "Type A",
    type_of_vouchers_opt3: "Type B",
    type_of_vouchers_opt4: "Type C",
    type_of_vouchers_opt5: "Type D",
    expiry_date: "Date d'expiry:",
    redeemed_store: "boutique d'échange:",
    vaild_date: "Date valide",
    description: "Description",
    vouchers_code: "Code du bon d’achat",
    done: "Terminé",
    no_coupon_msg: "Aucun bon disponible",
  },
  editProfile: {
    title: "Éditer le profil",
    member_profile: "Profil du membre",
    editProfile: "Éditer le profil",
    general: "Informations générales",
    security: "Sécurité",
    subscriptions: "Abonnements",
    salutation: "*Formule d’appel",
    salutation_opt1: "Veuillez choisir votre titre",
    salutation_opt2: "M.",
    salutation_opt3: "Mme",
    salutation_opt4: "Mme",
    salutation_opt5: "Mlle",
    salutation_opt6: "Dr",
    salutation_errMsg: "Veuillez choisir le titre",
    gender: "*Sexe",
    gender_opt1: "Veuillez sélectionner le sexe",
    gender_opt2: "Mâle",
    gender_opt3: "femelle",
    gender_errMsg: "Veuillez sélectionner le sexe",
    first_name: "*Prénom",
    first_name_errMsg: "Veuillez indiquer votre prénom",
    last_name: "*Nom",
    last_name_errMsg: "Veuillez indiquer votre nom",
    birth: "*Date de naissance",
    mobile: "*Numéro de portable",
    mobile_change: "Modifier",
    email: "*Adresse courriel",
    email_change: "Modifier",
    language: "*Langue",
    language_opt1: "Veuillez sélectionner votre langue de préférence",
    language_opt2: "English",
    language_opt3: "Français",
    update: "Mettre à jour",
    previous_password: "*Mot de passe précédent",
    previous_password_errMsg: "erreur de mot de passe précédent",
    new_password: "*Nouveau mot de passe",
    new_password_errMsg1: "Veuillez entrer le mot de passe",
    new_password_errMsg2: "Le mot de passe doit contenir au moins 8 caractères alphanumériques et au moins une majuscule et un caractère spécial (?!@$%&*)",
    confirm_password: "*Confirmez le mot de passe",
    confirm_password_errMsg: "Veuillez confirmer le mot de passe",
    subscriptions_txt1: "En cochant la ou les cases ci-dessous, je donne mon consentement à Imperial Treasure (France) (« ITRG ») pour collecter, utiliser, stocker et/ou divulguer toute donnée personnelle fournie par moi dans le cadre de l’adhésion à l’Imperial Treasure Club (« Données »). ITRG pourra également partager mes données avec l’une de ses sociétés associées ou apparentées ou d’autres tiers afin que je puisse profiter des avantages découlant de mon adhésion à l’Imperial Treasure Club, y compris les avantages découlant de la relation d’ITRG avec des prestataires de services en France et à l’étranger. Vous pouvez retirer votre consentement à tout moment.",
    subscriptions_txt2: "Je souhaite recevoir des messages marketing et promotionnels de la part d’Imperial Treasure (France).",
    by_email: "Par e-mail",
    by_mobile: "Par mobile",
    update_success: "Mise à jour réussie !",
    done: "Terminé",
    password_update_success: "Mise à jour du mot de passe réussie !",
  },
  profile: {
    title: "Membre",
    logout: "Déconnexion",
    click_to_renew: "CLIQUEZ POUR RENOUVELER​",
    activation_now: "Activer maintenant",
    menber_id: "Identifiant du membre:",
    member: "Membre",
    active: "Actif",
    expired: "Expiré",
    salutation: "Formule d’appel:",
    mobile: "Numéro de portable:",
    birth: "Date de naissance:",
    password: "Mot de passe:",
    ellipsis: "········",
    change: "Modifier",
    gender: "Sexe:",
    email: "Courriel:",
    cycle_start_date: "Date de début du cycle:",
    first_joined_date: "Date de première adhésion:",
    optin_email: "sélectionner l'e-mail:",
    optin_sms: "sélectionner SMS:",
    cycle_expiry_date: "Date d'expiration du cycle:",
    language_preference: "Préférence de langue:",
    spend: "€ Dépenser",
    expires_on: "  Expire le",
    spend_required: "Dépense nette requise",
    to_upgrade_to: "pour passer à",
    nett_spend_required: "Dépenses nettes nécessaires pour maintenir à",
    nett: "Nette",
    to_maintain_to: "maintenir à",
    silver_member: "Argent",
    gold_member: " membre de niveau Or",
    diamond_member: " membre de niveau Diamant",
    total_nett_spend: "Total des dépenses nettes",
    current_membership: "pendant l’année d’adhésion en cours*",
    i_dollar: "i-Dollar",
    last_update: "  Dernière mise à jour : ",
    i_dollar_earned: "i-Dollar gagné",
    i_dollar_converted: "i-Dollar converti",
    i_dollar_balance: "Solde en i-Dollars",
    i_dollar_automatically: "Les i-Dollars de 50 i$ sont automatiquement convertis en bon électronique de 50 € en espèces",
    vouchers: "Bons d’achat",
    currently_active: "actuellement valide",
    cash_vouchers: "Bons de caisse",
    food_vouchers: "Nourriture/coupons",
    click_to_view: "CLIQUE POUR VOIR",
    transaction: "Transaction",
    this_month: "Ce mois-ci",
    last_3_months: "des 3 derniers mois",
    last_12_months: "des 12 derniers mois",
    transaction_details: "détails de la transaction",
    transaction_date: "Date de la transaction",
    transaction_time: "Temps de transaction",
    outlet: "boutique",
    receipt: "Numéro de reçu",
    payment_method: "Mode de paiement",
    receipt_details: "Détails du reçu",
    zero: ".0000",
    i_dollars_eared: "i-Dollar gagné:",
    total: "Total :",
    done: "Terminé",
    thankyou_msg: "Nous vous remercions d’avoir dîné chez nous !",
    activate_membership: "Activer l'adhésion",
    activate_membership_1: "Activez votre abonnement pour profiter des privilèges suivants :",
    activate_membership_2: "Gagnez des i-Dollar (1 i$ pour chaque 10 € dépensés) sur vos dépenses dans tous les restaurants participants. Accumulez 50 i$ pour bénéficier d’un bon d’achat électronique de 50 €, à utiliser dans tous les restaurants participants.",
    activate_membership_3: "",
    activate_membership_4: "",
    activate_membership_5: "",
    activate_membership_6: "",
    activate_membership_7: "",
    renew_membership: "Renew Membership",
    renew_membership_1: "Click to Renew button for EXPIRED members: \n Your membership has expired. You can renew it now for $38 and receive the following e-vouchers during your new membership cycle.",
    renew_membership_2: "",
    renew_membership_3: "",
    renew_membership_4: "",
    confirm_to_renew: "Confirm To Renew",
    confirm_to_activate: "Confirmer pour activer",
    change_password: "Modifier le mot de passe",
    previous_password: "*Mot de passe précéden",
    previous_password_errMsg: "erreur de mot de passe précédent",
    new_password: "*Nouveau mot de passe",
    new_password_errMsg1: "Veuillez entrer et confirmer le mot de passe",
    new_password_errMsg2: "Le mot de passe doit contenir au moins 8 caractères alphanumériques et au moins une majuscule et un caractère spécial (?!@$%&*)​",
    confirm_password: "*Confirmez le mot de passe",
    close: "X CLOSE",
    member_activated: "L'adhésion est activée",
    password_updated: "Mot de passe mis à jour",
    view_more: "Voir plus",
    add_friend: "AJOUTER",
    silver_tier: "Argent ",
    gold_tier: "Or ",
    diamond_tier: "Diamant ",
    spend_date_format: "Année expirée",
  },
  transactionDetails: {
    transaction_date: "Date de la transaction",
    outlet: "Restaurant",
    receipt_no: "Numéro du chèque/reçu",
    amount_spend: "Total payé",
    amount_qualified_for_i_dollar: "Montant qualifié pour i-Dollar",
    i_dollars: "i-Dollar",
    selected_start_date: "Date de début sélectionnée",
    selected_end_date: "Date de fin sélectionnée",
    no_tranMsg: "Aucun enregistrement de transaction pendant la période sélectionnée.",
    action: "Action",
    apply: "Appliquer",
    view: "Voir",
  },
  contactUs: {
    title: "Contact",
    title_message: "Veuillez compléter et soumettre vos commentaires ci-dessous et nous vous répondrons dans les 3 à 5 jours ouvrables",
    firstname: "Prénom",
    firstname_errMsg: "Veuillez indiquer votre prénom",
    lastname: "Nom",
    lastname_errMsg: "Veuillez indiquer votre nom",
    emailAddress: "Adresse courriel",
    emailAddress_errMsg: "Veuillez saisir une adresse e-mail valide",
    mobile: "Numéro de portable",
    mobile_errMsg: "Veuillez entrer un téléphone valide",
    type: "Taper",
    type_opt1: "Veuillez entrer votre type de message de commentaires :",
    type_opt2: "Choix",
    type_opt3: "enquête",
    type_opt4: "Retour",
    type_opt5: "Inscription à la Newsletter",
    type_errMsg: "Veuillez entrer votre type de message de commentaires",
    message: "Message",
    message_errMsg: "Veuillez entrer votre message de commentaires",
    captcha: "Captcha",
    captcha_errMsg: "Captcha incorrect",
    feedback_sucMsg: "Merci de nous avoir contactés et de nous avoir fourni vos précieux commentaires. Nous vous répondrons très bientôt.",
    submit: "Nous faire parvenir",
  },
  languageSetting: {
    title: "Paramètres de langue",
    setting: "Paramètres",
    language_preference: "Préférence de langue",
    language_preference_opt1: "Veuillez sélectionner une option de langue",
    language_preference_opt2: "English",
    language_preference_opt3: "Français",
    language_preference_errMsg: "Veuillez sélectionner une option de langue",
    submit: "Nous faire parvenir",
  },
};