export default {
  login: {
    login: "LOGIN",
    email: "Email",
    mobile: "Mobile",
    mobile2: "MOBILE NO",
    password: "PASSWORD",
    errMsg1: "Please enter email & password",
    errMsg2: "Please enter mobile & password",
    errMsg3: "Your account isn't this region",
    errMsg4: "Incorrect login or password",
    errMsg5: "Incorrect login mobile or password",
    errMsg6: "Your registration is not complete until payment is received.",
    forgotpw: "Forgot password?",
    register: "Register",
    txt: "Not an Imperial Treasure Club member yet? Check out our ",
    txt_2: "Membership Privileges.",
  },
  header: {
    home: "HOME",
    eStore: "E-STORE",
    PRivileges: "PRivileges",
    register: "register",
    highlights: "highlights",
    faq: "faq",
    tnp: "terms & privacy",
    profile: "Profile",
  },
  footer: {
    aboutUs: "About Us",
    eStore: "E-STORE",
    faq: "FAQs",
    tnp: "Terms & Privacy",
    pr: "Participating Restaurant",
    profile: "Profile",
    contactUs: "Contact Us",
    copyright: "Copyright © 2023 Imperial Treasure Restaurant Group Pte Ltd.",
  },
  signUp: {
    signUp: "Membership Registration",
    title: "Register as a member of Imperial Treasure Club",
    mustFill: '"*" denotes required fields',
    step1: "Account Details",
    step2: "Security Information",
    step3: "Subscriptions",
    step4: "Reference",
    salutation: "Salutation",
    salutation_opt1: "Please select your salutation",
    salutation_opt2: "Mr",
    salutation_opt3: "Mrs",
    salutation_opt4: "Mdm",
    salutation_opt5: "Miss",
    salutation_opt6: "Dr",
    salutation_errMsg: "Please select salutation",
    firstname: "Given Name",
    firstname_errMsg: "Please fill in your given name",
    lastname: "Family Name",
    lastname_errMsg: "Please fill in your family name",
    birth: "Birth Date",
    birth_errMsg: "Please select date of birth",
    birth_errMsg2: "You must 18 years old or older",
    mobile: "Mobile No.",
    mobile_errMsg: "Please enter valid phone",
    mobile_errMsg2: "Phone already registered",
    emailAddress: "Email Address",
    emailAddress_errMsg: "Please enter valid email",
    emailAddress_errMsg2: "Email already registered",
    confirmEmailAddress: "Confirm Email Address",
    confirmEmailAddress_errMsg: "Please confirm email",
    gender: "Gender",
    gender_opt1: "Please select gender",
    gender_opt2: "Male",
    gender_opt3: "Female",
    gender_errMsg: "Please select gender",
    language_preference: "Language Preference",
    language_preference_opt1: "Please select your language preference",
    language_preference_opt2: "English",
    language_preference_opt3: "Français",
    language_preference_errMsg: "Please select language preference",
    password_format: "Password must contain at least 8 alphanumeric characters and with at least one uppercase and one special character (?!@$%&*)",
    password: "*Password",
    password_errMsg: "Please enter password",
    confirm_password: "*Confirm Password",
    confirm_password_errMsg: "Please Enter password again",
    step3_consent_opt: "I acknowledge that I have read and agree to the <link>Terms and Conditions<link> set forth herein.",
    step3_consent_txt: "Please look at our <link>Privacy Notice<link> for information about how Imperial Treasure will process your personal data.",
    tnc_errMsg: "Please agree to Terms & Conditions to proceed",
    step3_title: "By checking the box(es) below, I give my consent to Imperial Treasure (France) (“ITRG”) to collect, use, store and/or disclose any personal data provided by me in connection with the Imperial Treasure Club membership (“Data”) for ITRG to share my Data with any of its associated or related companies or other third parties so that I may enjoy the benefits arising from my membership of the Imperial Treasure Club, including benefits arising from ITRG’s relationship with service providers within and outside of France. You can withdraw your consent at any time.",
    step3_q: "I would like to receive marketing and promotional messages and materials from Imperial Treasure (France).",
    step3_q_opt1: "By Email",
    step3_q_opt2: "By Mobile",
    step4_q_err: "Please select where did you hear about our programme",
    step4_q: "Where did you hear about our programme?",
    step4_q_opt1: "Select a reference",
    step4_q_opt2: "Imperial Treasure Fine Chinese Cuisine",
    step4_q_opt3: "Word of Mouth",
    step4_q_opt4: "Social Media",
    step4_q_opt5: "Others",
    promo_code: "Promo Code",
    promo_code_input_msg: "Please enter a valid promo code (if any)",
    promo_code_err_msg: "Please enter valid promo code.",
    promo_code_re_msg: "Promo code is valid!",
    total: "Total:",
    refresh: "Refresh",
    captcha: "Captcha",
    captcha_errMsg: "Captcha incorrect",
    cancel: "Cancel",
    next: "Next",
    back: "Back",
    submitForm: "Proceed to payment",
  },
  aboutUs: {
    aboutUs: "ABOUT US",
    txt1: "The Imperial Treasure Restaurant Group",
    txt2: "With our vast array of iconic Chinese delicacies and our commitment to impeccable service, Imperial Treasure has become synonymous with authentic fine Chinese cuisine. Since the establishment of our first restaurant in 2004, Imperial Treasure Restaurant Group has won the hearts of gourmands and casual diners alike.",
    txt3: "Today, the Group has more than 20 restaurants under its name, as well as over 50 accolades – including: 2-Michelin Stars in the Michelin Guide Shanghai (2017 – 2023) for Imperial Treasure Fine Chinese Cuisine (Yi Feng Galleria), 2-Michelin stars in the Michelin Guide Guangzhou  (2020 – 2022) and 1-Michelin star in the Michelin Guide Guangzhou  (2019) for Imperial Treasure Fine Chinese Cuisine (IGC), 1-Michelin star in the Michelin Guide Singapore (2017 – 2019,  2021 – 2022) for Imperial Treasure Fine Teochew Cuisine (ION Orchard), 1-Michelin star in the Michelin Guide Hong Kong Macau (2018 – 2022) for Imperial Treasure Fine Chinese Cuisine (Tsim Sha Tsui), 1-Michelin star in the Michelin Guide Guangzhou (2021 – 2022) for Imperial Treasure Fine Teochew Cuisine (Taikoo Hui), Michelin Plate by Michelin Guide Guangzhou (2018, 2020), Michelin Plate by Michelin Guide France (2020), Michelin Plate by Michelin Guide Singapore (2018 – 2019), Michelin Plate by Michelin Guide Great Britain & Ireland (2019), Two-Diamond in Meituan-Dianping’s Black Pearl Guide (2018), Top Chinese Restaurants of the World by Ch’ng Poh Tiong, Asia's 50 Best Restaurants (2013 – 2015).",
    txt4: "Imperial Treasure has restaurants in Singapore, Shanghai, Guangzhou, Hong Kong, Incheon, London and Paris.",
  },
  faqs: {
    faqs: "FAQs",
    general: "General",
    general_q1: "What is the Imperial Treasure Club?",
    general_q1_opt1: "Imperial Treasure Club is a cardless loyalty programme that allows members to enjoy exclusive privileges and rewards whenever they dine at any participating Imperial Treasure restaurants.",
    general_q2: "How do I become a member of Imperial Treasure Club?",
    general_q2_opt1: "You may register online at members.imperialtreasure.fr.",
    general_q3: "Which is the participating restaurant in Paris?",
    general_q3_opt1: "Imperial Treasure Fine Chinese Cuisine",
    general_q3_opt1_1: "44 Rue de Bassano, 75008 Paris France | Tel: +33 (0) 1 58 56 29 13",
    general_q4: "What is the validity of the membership?",
    general_q4_opt1: "The membership is valid for 1 year from the date of sign up. E.g. If the registration is made on 17 August 2024, the membership will expire on 31 August 2025.",
    general_q5: "Where can I check the expiry date of my membership?",
    general_q5_opt1: "You may log in to the members' portal to check the expiry date of your membership.",
    general_q6: "What is the minimum age that I can apply as an Imperial Treasure Club member?",
    general_q6_opt1: "Customers need to be above 18 years of age to apply as an Imperial Treasure Club member.",
    general_q7: "Why should I register my details at Imperial Treasure Club? ",
    general_q7_opt1: "Registering your details at https://members.imperialtreasure.fr is to activate your account. In addition, you will also receive the latest news on upcoming members’ promotions, rewards, invitations to events and more!",
    general_q8: "Where do I update my personal particulars?",
    general_q8_opt1: "To update or view your personal particulars, please log in to your account via the members’ portal at members.imperialtreasure.fr and click on “Profile”. For assistance to update your date of birth, kindly write in to fr.club@imperialtreasure.com.",
    general_q9: "How do I check my i-dollars, e-vouchers and other rewards?",
    general_q9_opt1: "You may log in to members.imperialtreasure.fr to check your membership privileges and rewards balance.",
    general_q10: "What if I forgotten my password?",
    general_q10_opt1: "To reset your password, visit the members’ portal at members.imperialtreasure.fr and click on “Forgot Password”. Follow the on-screen instructions to reset your password.",
    general_q11: "What should I do if I have questions regarding my membership account?",
    general_q11_opt1: "If you have any questions regarding your membership account, please email us at fr.club@imperialtreasure.com.",
    general_q12: "Is my Imperial Treasure Club membership transferrable?",
    general_q12_opt1: "No. Membership is not transferrable.",
    general_q13: "How do I terminate my membership?",
    general_q13_opt1: "Member may choose to terminate their membership at any time before the expiry date by writing in to fr.club@imperialtreasure.com. Kindly be advised that there will be no refunds made and/or early issuance of any unused e-vouchers at the point of termination. Alternatively, your membership will automatically expire upon non-renewal of membership, and all balance i-dollars and/or unused e-vouchers will be deemed forfeited.",
    membershipTypes: "Membership Types",
    membershipTypes_q1: "What are the membership tiers?",
    membershipTypes_q1_opt1: "There are 3 membership tiers - Silver, Gold and Diamond.",
    membershipTypes_q2: "How to be a Silver / Gold / Diamond member?",
    membershipTypes_q2_opt1: "To become a Silver member, it is by invitation and you can sign up via online.",
    membershipTypes_q2_opt2: "To become a Gold member, existing members need to accumulate the qualifying spend of 8888€* and above within the membership year to qualify.",
    membershipTypes_q2_opt3: "To become a Diamond member, existing members need to accumulate the qualifying spend of 23888€* and above within the membership year to qualify.",
    membershipTypes_q2_opt4: "*Qualifying spend is calculated before service charge (if any), VAT, other terms and conditions apply.",
    membershipTypes_q3: "How to retain my membership status?",
    membershipTypes_q3_opt1: "To maintain as a Gold member, simply accumulate the qualifying spend of 8888€* within the membership year to renew your membership status.",
    membershipTypes_q3_opt2: "To maintain as a Diamond member, simply accumulate the qualifying spend of 23888€* within the membership year to renew your membership status.",
    membershipTypes_q3_opt3: "*Qualifying spend is calculated before service charge (if any), VAT, other terms and conditions apply.",
    membership: "Membership Privileges & Rewards",
    membership_q1: "What are the membership privileges?",
    type_of_privileges: "Type of Privileges",
    sliver: "Sliver",
    gold: "Gold",
    diamond: "Diamond",
    complimentary_treats: "Complimentary Treats",
    complimentary_treats_1_1: "1 X Peking Duck",
    complimentary_treats_1_2: "-",
    complimentary_treats_1_3: "Yes",
    complimentary_treats_1_4: "Yes",
    complimentary_treats_2_1: "1 X Mooncake Set (Seasonal)",
    complimentary_treats_2_2: "-",
    complimentary_treats_2_3: "Yes",
    complimentary_treats_2_4: "Yes",
    complimentary_treats_3_1: "1 X Red Wine",
    complimentary_treats_3_2: "-",
    complimentary_treats_3_3: "-",
    complimentary_treats_3_4: "Yes",
    complimentary_treats_4_1: "1 X Champagne",
    complimentary_treats_4_2: "-",
    complimentary_treats_4_3: "-",
    complimentary_treats_4_4: "Yes",
    complimentary_treats_5_1: "1 X Rose Lobster",
    complimentary_treats_5_2: "-",
    complimentary_treats_5_3: "-",
    complimentary_treats_5_4: "Yes",
    complimentary_treats_6_1: "1 X 300€ e-voucher",
    complimentary_treats_6_2: "-",
    complimentary_treats_6_3: "-",
    complimentary_treats_6_4: "Yes",
    membership_q3: "How do I know the expiry of my privileges?",
    membership_q3_opt1: "To check the expiry dates of your privileges, simply log in to the members' portal members.imperialtreasure.fr and view the details reflected under the 'Vouchers' section.",
    membership_q4: "How do I identify myself as an Imperial Treasure Club member?",
    membership_q4_opt1: "To identify yourself as a member, simply provide your mobile number to our service staff.",
    membership_q5: "How do I enjoy my complimentary treats?",
    membership_q5_opt1: "For redemption of food/wine items, one (01) day advance order is required and available at Imperial Treasure Fine Chinese Cuisine. For redemption of 300€ e-voucher, simply inform the service staff prior to payment. Member must be physically present (proof of identification required) to redeem any membership privileges.",
    membership_q6: "Can I as a corporate customer enjoy corporate discount with the Imperial Treasure Club membership?",
    membership_q6_opt1: "No, corporate discount is not eligible for use together with the Imperial Treasure Club membership. Corporate customers can only choose either corporate discount or the Imperial Treasure Club membership for each receipt.",
    membership_q7: "Can I enjoy my Imperial Treasure Club membership privileges in another country where there is an Imperial Treasure restaurant?",
    membership_q7_opt1: "For earning of i$, it is allowed in any Imperial Treasure restaurants regardless of the location. The i$ conversion will be based on registered base country and according to currency rate set forth by Imperial Treasure. For redemption of e-vouchers, you can only redeem in the country that you registered the account in.",
    membership_q8: "Is my Imperial Treasure Club membership transferrable?",
    membership_q8_opt1: "No. Membership is not transferrable.",
    earning: "Earning & Redemption of points",
    earning_q1: "How do I earn i$?",
    earning_q1_opt1: "Earn i$1 for every 10€* spent at participating restaurants (*excludes service charge, prevailing government taxes, e-vouchers and purchase or use of Imperial Treasure dining vouchers and festive items). Member must be physically present (proof of identification required) at the point of dining. Requests on i$ accumulation for previous or backdated receipts will not be entertained. The system may require up to one (01) working day to reflect the transactions, e-vouchers and i$ earned. Combining multiple membership accounts or splitting the bill to earn i$ is strictly not allowed. Balance i$ accrued shall expire upon expiry of membership.",
    earning_q2: "How do I redeem my i$?",
    earning_q2_opt1: "For every i$50 earned, members will automatically receive a 50€ e-voucher* valid for use within the membership year. The 50€ e-voucher can be used to offset the bill when dining at the participating restaurant. Member must be physically present (proof of identification required) at the point of dining to redeem any membership privileges. Member is required to sign off on the receipt to acknowledge the redemption(s) made. Combining multiple membership accounts or splitting the bill to redeem membership privileges is not allowed.\n(*system automatically converts i$ at a stipulated time daily)",
    earning_q3: "Can I earn i$ or redeem membership privileges for online purchases made at the e-store?",
    earning_q3_opt1: "No. Earning of i$ and/or redeeming of membership privileges are only available for purchases made at participating restaurants, unless otherwise specified.",
    earning_q4: "Will my i$ expire?",
    earning_q4_opt1: "Yes. i$ earned is only valid during the membership period and will expire once the membership expires. Balance i$ will be brought forward for a grace period of three (03) months upon expiry of membership and must be converted to a 50€ e-voucher within the grace period, otherwise deemed forfeited.",
    earning_q5: "Will my i$ be reflected immediately once I make a purchase?",
    earning_q5_opt1: "Yes. The system will reflect i$ earned instantly but do allow for time lapses that may be caused by any delays in the network during unforeseen circumstances.",
    earning_q6: "Can I use multiple membership accounts to earn i$ and redeem membership privileges?",
    earning_q6_opt1: "No. Combining multiple membership accounts to earn i-dollar or redeem membership privileges is not allowed.",
    earning_q7: "Can I split my bill to earn i$ and/or redeem membership privileges?",
    earning_q7_opt1: "No. Splitting of bill to earn i$ and/or redeem membership privileges is not allowed.",
    earning_q8: "Can my family and friends earn i$ or redeem membership privileges if I am not present?",
    earning_q8_opt1: "No. Members must be present in order to enjoy the membership privileges.",
    earning_q9: "Can I earn i$ or redeem membership privileges for orders made via online orders made via third party platforms?",
    earning_q9_opt1: "No. Earning of i$ and redemption of membership privileges are not eligible for online orders made via third party platforms.",
  },
  tnc: {
    title: "Terms And Privacy",
    consent: "Consent wording on sign-up page",
    consent_1_1: "□ I acknowledge that I have read and agree to the Terms and Conditions [link: Members.imperialtreasure.fr/tnc] set forth herein.",
    consent_1_2: "Please look at our Privacy Notice for information about how Imperial Treasure will process your personal data [link: Members.imperialtreasure.fr/tnc]”.",
    consent_opt: "Opt-in Wording on sign-up page",
    consent_2_1: "By checking the box(es) below, I give my consent to Imperial Treasure (France) (“ITRG”) to collect, use, store and/or disclose any personal data provided by me in connection with the Imperial Treasure Club membership (“Data”) for ITRG to share my Data with any of its associated or related companies or other third parties so that I may enjoy the benefits arising from my membership of the Imperial Treasure Club, including benefits arising from ITRG’s relationship with service providers within and outside of France. You can withdraw your consent at any time.",
    consent_2_2: "I would like to receive marketing and promotional messages and materials from Imperial Treasure (France).",
    consent_2_3: "□ By Email    □ By Post     □ By Mobile",
    membership: "Membership Terms And Privacy",
    membership_1_1: "1.1 Introduction",
    membership_1_1_1: 'Imperial Treasure (France) (“Imperial Treasure”) would like to offer our privileged customers news and information regarding our products and services, including promotions, special offers and discounts.  We are offering you the opportunity to become a member of the Imperial Treasure Club (a "Member") to enjoy special privileges that Imperial Treasure may offer from time to time.',
    membership_1_2: "1.2 Collection and use of personal data",
    membership_1_2_1: "If you become a Member, you will need to provide Imperial Treasure with certain personal information (including your name and contact details such as email address and phone number).  You will need to provide us with your mobile number for us to identify you. Your mobile number will be your username or unique identifier as a member of Imperial Treasure Club. Any personal data held by us about you will be held in accordance with our Privacy Notice.",
    membership_1_3: "1.3 Direct marketing",
    membership_1_3_1: 'By clicking on the "SUBMIT" button below, you agree that Imperial Treasure, our affiliates, related entities, and third parties acting on our behalf ("Business Partners"), may collect, use and disclose your personal data, which you have provided at the member\'s portal at https://members.imperialtreasure.fr (the "Member\'s Portal"), for providing marketing material that you have agreed to receive, in accordance with the EU General Data Protection Regulation (“GDPR”) and our Privacy Notice. Please note that marketing material may be sent directly by our Business Partners, and we may disclose your personal data to them for that purpose.',
    membership_1_3_2: 'As a consumer, you have the right to register on the telephone direct marketing opt-out list “Bloctel”. Bloctel is the telephone marketing opt-out list on which any consumer can register free of charge to no longer be contacted by phone by a professional with whom they have no current contractual relationship, in accordance with the French consumer law provisions (Article L. 223-22 of the French Consumer Code). Under French law, it is prohibited for any professional, directly or through a third party acting on his behalf, to phone a consumer registered on the Bloctel list, without any exemptions provided by the law.',
    membership_1_4: "1.4 Membership Tiers",
    membership_1_4_1: "1.4.1 Membership registration, i-dollars, and Cash e-Vouchers",
    membership_1_4_1_1: "Where you sign up to become a Member, you will be required to provide your mobile number, among other information we may require from you, in order to sign up for the membership. This may be required online or by our restaurant staff depending on the circumstances.",
    membership_1_4_1_2: "Membership is strictly non-transferable and shall only be enjoyed by the Member in person.",
    membership_1_4_1_3: "As a Member, you earn one (01) i-dollar at the rate specified on the membership programme website [https://members.imperialtreasure.fr] for the country in which you signed up, when you spend at any participating Imperial Treasure restaurants (excluding service charge, VAT, Cash e-vouchers used, and the purchase and use of Imperial Treasure gift vouchers). Please refer to the Member’s Portal for a list of participating Imperial Treasure restaurants. Member must be physically present at the point of dining and provide the registered mobile number to the service staff in order to earn i-dollars. Earning of i-dollars must be made at the point of transaction. Requests on i-dollar accumulation for previous or backdated receipts will not be entertained. The system may require up to one (01) working day to reflect the transactions, Cash e-vouchers and i-dollar earned. I-dollars accrued shall expire upon the expiry of membership. Any i-dollar accrued is however granted a three (03) months grace period and must be converted to Cash e-Vouchers within this grace period, failing which such i-dollar shall be deemed forfeited. Transactions made via third party vendors are not eligible for earning of i-dollars.",
    membership_1_4_1_4: "Earned i-dollars are converted in the Member Portal into Imperial Treasure e-vouchers of a specified value (“Cash e-Vouchers”) at the rate specified on the membership programme website [https://members.imperialtreasure.fr] for the country in which you signed up. Each Cash e-Voucher is valid for twelve (12) months from date of issue and valid for use within the membership period only. The Cash e-Voucher can be used to offset the bill when dining at any participating Imperial Treasure restaurants and any other terms and conditions stated at [https://members.imperialtreasure.fr]. Neither i-dollars nor Cash e-Vouchers have any cash value and cannot be converted or redeemed for cash.",
    membership_1_4_2: "1.4.2 Membership Tiers",
    membership_1_4_2_1: "Unless otherwise determined by Imperial Treasure, Silver Tier status is conferred on Members when they are invited to first join the membership programme and is valid for the period specified on the membership programme website [https://members.imperialtreasure.fr] for the country in which you signed up. At the end of this period, if the Member has not been upgraded to the Gold Tier or Diamond Tier, the Member may be required to pay such renewal fee or meet the relevant renewal requirements as may be prescribed by Imperial Treasure on the membership website [link] for the country in which you signed up.",
    membership_1_4_2_2: "Gold Tier status is conferred on Members who spend at least the amount set out for Gold Tier on the membership programme website [https://members.imperialtreasure.fr] for the country in which you signed up, at participating Imperial Treasure restaurants within twelve (12) months. Gold Tier status is valid for twelve (12) months from the date the Member qualifies; after that, annual requalification is required. Upon expiration of your membership tenure as a Gold Tier member, and if you have not met the minimum spend requirement to be retained in the tier, you shall be downgraded to Silver Tier status.",
    membership_1_4_2_3: "Diamond Tier status is conferred on Members who spend at least the amount set out for Diamond Tier on the membership programme website [https://members.imperialtreasure.fr] for the country in which you signed up, at participating Imperial Treasure restaurants within twelve (12) months. Diamond Tier status is valid for twelve (12) months from the date the Member qualifies; after that, annual requalification is required. Upon expiration of your membership tenure as a Diamond Tier member, and if you have not met the minimum spend requirement to be retained in the tier, you shall be downgraded to Gold Tier or Silver Tier status accordingly.",
    membership_1_4_2_4: "Imperial Treasure may alter, limit, or modify the Silver Tier, Gold Tier and/or Diamond Tier programme rules, regulations, benefits, eligibility for membership, or any other feature of the Silver Tier, Gold Tier and/or Diamond Tier programme and/or may terminate the Silver Tier, Gold Tier and/or Diamond Tier programme at any time, by giving reasonable notice on the membership programme website and/or by email. Please check the membership programme website regularly for updates.",
    membership_1_4_3: "1.4.3 Membership Tier Rewards",
    membership_1_4_3_1: "Each membership tier also entitles Members to certain rewards and privileges, which may include other e-vouchers, free gifts and/or other dining benefits (“Rewards”). The current tier privileges are as set out in the membership programme website [https://members.imperialtreasure.fr]. The validity and terms and conditions of such Rewards are as set out in [https://members.imperialtreasure.fr], which may be amended by Imperial Treasure by giving reasonable notice by email. Rewards are always subject to availability. Imperial Treasure reserves the right to replace a Reward with another Reward of equivalent or similar value. Rewards do not have any cash value and cannot be converted or redeemed for cash.",
    membership_1_4_4: "1.4.4 Redemption of Rewards and Cash e-Vouchers",
    membership_1_4_4_1: "Member must be physically present (proof of identification required) at the point of dining in order to redeem any membership privileges. Member is required to sign off on the receipt to acknowledge the redemption(s) made. Redemption of Rewards are subject to prevailing local law requirements.",
    membership_1_4_4_2: "Combining multiple membership accounts, combining bills or splitting the bills to earn i-dollars and/or redeem Rewards and membership privileges is strictly not allowed. Membership must be active at the point of redemption.",
    membership_1_5: "1.5 Amending personal data and terminating membership",
    membership_1_5_1: "You may amend your personal data or marketing preferences at any time via our Member’s Portal. You will need to inform us in writing at fr.club@imperialtreasure.com should you choose to terminate your membership. Please allow a few working days for changes to take effect. Kindly be advised that there will be no refunds made and/or early issuance of any unused Cash e-vouchers at the point of termination. Alternatively, your membership will automatically expire upon non-renewal of membership, and all balance i-dollars and/or unused Cash e-vouchers will be deemed forfeited.",
    membership_1_6: "1.6 Termination and cancellation",
    membership_1_6_1: "We reserve the right to terminate the Member's membership if the membership account is found to be shared with another non-Member. \nWe reserve the right to terminate your membership, in our absolute discretion, should you abuse any of the conditions and privileges bestowed on you as a Member.",
    membership_2: "2. Law and forum",
    membership_2_1: "These Terms, including all policies and additional terms referenced herein (“Membership Agreement”),  are governed by and construed in accordance with the laws of Singapore. If you are a consumer and we direct our services and/or offers in the country in which you are resident, you will benefit from any mandatory provisions of the law of the country in which you are resident.  Nothing in this Membership Agreement affects your rights as a consumer or your other statutory rights to rely on such mandatory provisions of any regulations.",
    membership_2_2: "Any dispute or claim arising out of or in connection with this Membership Agreement, (including non-contractual disputes or claims) shall be subject to the exclusive jurisdiction of Singapore courts. If you are a consumer, you may bring any dispute which may arise under this Membership Agreement to the competent court of your country of habitual residence if this country of habitual residence is within the EEA.",
    privacy: "Privacy Notice",
    privacy_1: "1. INTRODUCTION",
    privacy_1_1: 'Imperial Treasure Restaurant Group Pte Ltd, Imperial Treasure Cantonese Cuisine Pte Ltd, Imperial Treasure Noodle House Pte Ltd, Imperial Treasure Fine Dining Restaurant Pte Ltd, Multirich F&B Management Pte Ltd, Imperial Treasure Windows of Hong Kong Pte Ltd and Imperial Treasure Steamboat Pte Ltd (collectively referred as "Imperial Treasure", "us", "we") is committed to protect the privacy of visitors to this website (the "Website"). This Privacy Notice sets out how Imperial Treasure collects, uses or discloses personal data that you have provided to us through the Website.',
    privacy_1_2: "The controller according to the EU General Data Protection Regulation (hereinafter, the “GDPR”) is: \n\n Name of the Data Controller: IT France \n\n Tel.: +33 (0) 1 58 56 29 13 \n\nE-Mail: fr.marketing@imperialtreasure.com \n\nWebsite: www.imperialtreasure.com/france/",
    privacy_1_3: 'This Privacy Notice does not apply to other websites to which we link ("Third Party Website" or "Third Party Websites").',
    privacy_1_4: "This Privacy Notice also describes your data protection rights, including a right to object to some of the processing which Imperial Treasure carries out. More information about your rights, and how to exercise them, is set out in the following section: “9. What rights do I have”.",
    privacy_1_5: 'Maintaining the privacy of our customers’ information is important to us. We do not disclose rent or sell our customers’ personal data to third parties (except as described in "6. Disclosures of personal data to third parties").',
    privacy_2: "2. COLLECTION AND USE OF PERSONAL DATA",
    privacy_2_1: "We collect the following personal data when you register as a user of the Website and make purchases through the Website:",
    privacy_2_1_1: "email address",
    privacy_2_1_2: "your name, address and phone number (including delivery addresses if different);",
    privacy_2_1_3: "billing address, including your name, address and phone number; and",
    privacy_2_1_4: "any other personal data provided to us when you submit any comment, question or feedback to us, request services from us, or disclose your personal data to us in any way",
    privacy_2_2: "We use your personal data for the following purposes and on the following legal basis:",
    privacy_2_2_1: "To fulfil a contract, or take steps linked to a contract: this is relevant where you make a purchase from us. This includes:",
    privacy_2_2_1_1: "process your application as a Member and provide you with the membership privileges;",
    privacy_2_2_1_2: "fulfil your order and deliver it to you;",
    privacy_2_2_1_3: "facilitate and track your purchases from us (including in our restaurants or via our websites); and",
    privacy_2_2_1_4: "provide you with service support;",
    privacy_2_2_2: "To serve our legitimate interest in conducting and improving its business, offering goods and services and preventing fraud, for the following purposes:",
    privacy_2_2_2_1: "to facilitate your registration as a Member;",
    privacy_2_2_2_2: "to use information you provide to provide products and services you have requested;",
    privacy_2_2_2_3: "(if you use a card payment for any purchase on the Website) we use third parties to check the validity of the sort code, account number and card number you submit in order to prevent fraud; and",
    privacy_2_2_2_4: "to respond to comments, queries and feedback.",
    privacy_2_2_3: "Where you give us consent:",
    privacy_2_2_3_1: "we provide you with marketing and promotional material and other information about our products (including promotions and special offers), as well as other products and services provided by our affiliates and partners and",
    privacy_2_2_3_2: "on other occasions where we ask you for consent, we will use the data for the purposes which we explain at that time.",
    privacy_2_2_4: "To comply with a legal obligation to which we are subject, for purposes which are required by law, or in response to requests by government or law enforcement authorities conducting an investigation.",
    privacy_2_3: "The provision of your name, family name, contact number,  country code and email address are required so that we can contact you and fulfil any of your order(s): if required data is not provided, then we will not be able to fulfil your order(s). All other provision of your information is optional. In any cases, when the collection of your personal data is required to execute a contractual obligation we have with you or to comply with our legal obligation, we will inform you thereof at the time of collection, via an asterisk.",
    privacy_3: "3. COOKIES AND SIMILAR TECHNOLOGIES",
    privacy_3_1: "Additionally, through the Website, we may gather certain information about the use of the Website, such as how frequently certain areas of the Website are visited, including through the use of cookies, web beacons, and other technologies.",
    privacy_3_1_1: "What are cookies?",
    privacy_3_1_1_1: "A cookie is a small text document, which often includes an anonymous unique identifier. Cookies are created when your browser loads a particular website. The website sends information to the browser which then creates a text file. Every time the user goes back to the same website, the browser retrieves and sends this file to the website's server. Find out more about the use of cookies on www.allaboutcookies.org.",
    privacy_3_1_1_2: "We also use other forms of technology (such as web beacons and, in apps, software development kits (usually referred to as SDKs)) which serve a similar purpose to cookies, and which allow us to monitor and improve our Website and email communications. When we talk about cookies in this Privacy Notice, this term includes these similar technologies.",
    privacy_3_1_2: "What cookies do we use and what information do they collect?",
    privacy_3_1_2_1: "We may use cookies, for the following purposes:",
    privacy_3_1_3: "Required cookies:",
    privacy_3_1_3_1: "These cookies are required to enable core functionality. Without these cookies, services you have asked for, like adding items to your shopping basket, cannot be provided. If you disable these cookies, certain parts of the Website will not function for you. For example, adding items to your basket, proceeding to checkout, identifying the causes of problems arising at web servers and resolving these problems. The processing of personal data using required cookies which are strictly necessary for the provision of services you have requested is based on Art. 6 (1) (b) GDPR.",
    privacy_3_1_4: "Functional cookies:",
    privacy_3_1_4_1: 'We may use cookies that are not essential but enable various helpful features on our Website. For example, these cookies collect information about your interaction with services provided on the Website and may be used on our Website to remember your preferences (such as your language preference), your interests and the presentation of the Website (such as the font size). For functional cookies, depending on the described purposes of use, the legal basis for the processing of personal data using cookies and other technologies either lies in Art. 6 (1) (f) GDPR, or if you have given us your consent to the use of cookies through the "cookie banner" displayed on the website, on Art. 6 (1) (a) GDPR.',
    privacy_3_1_5: "Advertising cookies:",
    privacy_3_1_5_1: 'We use these cookies to collect information about your browsing habits in order: to customize the content of the Website and our emails to suit your individual interests or purposes and selectively display best-matched pages to you, including tracking your past purchases in order to provide you with new services or special promotions. For advertising cookies, the legal basis for the processing of personal data using cookies and other technologies either lies in Art. 6 (1) (f) GDPR, or if you have given us your consent to the use of cookies through the "cookie banner" displayed on the website, on Art. 6 (1) (a) GDPR.',
    privacy_3_1_6: "Analytics cookies:",
    privacy_3_1_6_1: 'These cookies help us improve or optimise the experience we provide. They allow us to measure how visitors interact with the Website and we use this information to improve the user experience and performance of the Website. These cookies are used to: utilize your browsing history on the Website for demographic research; and obtain statistic data of web visitors and aggregated website visitation statistics which are anonymous in nature (such as the last visited Website, the number of pages visited, whether or not email communications are opened, which parts of our website or email communication are clicked on and the length of time between clicks). For analytics cookies, the legal basis for the processing of personal data lies in Art. 6 (1) (a) GDPR if you have given us your consent to the use of cookies through the "cookie banner" displayed on the website.',
    privacy_3_1_7: "Social media cookies:",
    privacy_3_1_7_1: 'These cookies are used when you engage with our content on or through a social site such as Facebook or Instagram. These cookies collect information about your social media interaction with the Website, such as whether you have an account with the social media site and whether you are logged into it when you interact with content on the Website. This information may be linked to targeting/advertising activities. For social media cookies, the legal basis for the processing of personal data lies in Art. 6 (1) (a) GDPR if you have given us your consent to the use of cookies for such purposes through the "cookie banner" displayed on the website.',
    privacy_3_1_8: "You may access and change your cookie preferences at any time by clicking [www.imperialtreasure.com/france/].",
    privacy_3_2: "Third parties",
    privacy_3_2_1: "Your use of the Website may result in some cookies being stored that are not controlled by us. This may occur when the part of the Website you are visiting makes use of third party analytics or marketing automation/management tool or includes content displayed from a third party website, for example, Facebook. You should review the privacy and cookie policies of these services to find out how these third parties use cookies and whether your personal data will be transferred to a third country. A list of the third parties who place cookies on the Website can be found here.",
    privacy_3_3: "How do you manage these technologies?",
    privacy_3_3_1: "We keep information collected from cookies for [a maximum of twenty (24) months.",
    privacy_4: "4. SENDING OF EMAILS TO MEMBERS",
    privacy_4_1: "Genuine emails from Imperial Treasure always will come from an address ending @imperialtreasure.com. Do not open any attachments or click any links from suspicious emails. If you wish to report a suspicious email claiming to be from Imperial Treasure that you believe is a forgery, you may email to fr.marketing@imperialtreasure.com.",
    privacy_5: "5. RELYING ON OUR LEGITIMATE INTERESTS",
    privacy_5_1: "We have carried out balancing tests for all the data processing we carry out on the basis of our legitimate interests, which we have described above. You can obtain information on any of our balancing tests by contacting us using the details set out later in this notice.",
    privacy_6: "6. WITHDRAWING CONSENT OR OTHERWISE OBJECTING TO DIRECT MARKETING ",
    privacy_6_1: "Wherever we rely on your consent, you will always be able to withdraw that consent at any time. Consequently, we are no longer allowed to continue the data processing based on this consent for the future, although we may have other legal grounds for processing your data for other purposes, as set out above.",
    privacy_6_2: "In some cases, we can send you direct marketing without your explicit consent, where we rely on our legitimate interests.  You have an absolute right to opt-out of direct marketing, or profiling we carry out for direct marketing, at any time. You can do this by following the instructions in the communication where it is an electronic message, or by contacting us using the details set out below.",
    privacy_7: "7. DISCLOSURES OF PERSONAL DATA TO THIRD PARTIES",
    privacy_7_1: "In order to provide services to you, we may disclose to third parties and/or transfer your personal data out of France, as described in this Privacy Notice:",
    privacy_7_1_1: "We may share your personal data with our affiliated companies for the purposes of: Fulfilling a contract with you, providing customer services and membership services, and facilitating your registration as a user of the Website:",
    privacy_7_1_2: "We may permit certain trusted third party processors to access your personal data in connection with their performance of services to maintain and operate the Website and certain features on the Website. For example, we may use third parties to host the Website; operate various features available on the Website; analyze data; provide search results and links and assist in fulfilling your orders and may share personal data or other information with our parent, subsidiaries, divisions, and affiliates. These third party instructed will use your personal data only as processors by Imperial Treasure and in a manner consistent with this Privacy Notice, and are prohibited from using or disclosing your personal data for any other purpose.",
    privacy_7_1_3: "We may employ a third-party service to collect the above-mentioned information, including personal data, and use their tracking services on our behalf. However, this information is strictly confidential and will not be shared with others. This third-party service will use your personal data only as instructed by Imperial Treasure and in a manner consistent with this Privacy Notice, and is prohibited from using or disclosing your personal data for any other purpose.",
    privacy_7_1_4: "We may disclose personal data as an asset in connection with a proposed or actual merger or sale (including any disclosures made as part of an insolvency or bankruptcy proceeding) involving all or part of our business or as part of a corporate reorganization, stock sale or other change in control.",
    privacy_7_1_5: "We reserve the right to disclose information, including personal data, in order to comply with legal requirements, such as a subpoena, court order, administrative or governmental order, or any other requirement of law, or when we, in our sole discretion, believe it is necessary in order to protect our rights or the rights of others, to prevent harm to persons or property, to fight fraud and credit risk reduction. We may also disclose or transfer personal data as otherwise permitted or required by law.",
    privacy_7_2: "Your personal data may be transferred out of France from time to time and shared with other countries for cross-border membership benefits. Such transfers will be carried out in accordance with the requirements of the GDPR and of the French Data Protection of 6 January 1978.",
    privacy_7_3: 'Where information is transferred outside the EEA, and where this is to a stakeholder or vendor in a country that is not subject to an adequacy decision by the EU Commission, data is adequately protected by EU Commission approved standard contractual clauses, an appropriate Privacy Shield certification or a vendor\'s Processor Binding Corporate Rules.  A copy of the relevant mechanism can be provided for your review on request to our Data Protection Office (please refer to the section below: "12. Contact Us").',
    privacy_8: "8. HOW WE PROTECT YOUR INFORMATION",
    privacy_8_1: "We are committed to protecting the information we receive from you. We adopt reasonable physical, technical and administrative practices to help protect the confidentiality, security and integrity of data, including personal data, stored on our system. We believe the measures we have implemented reduce the likelihood of security problems to a level appropriate to the type of data involved. Although we take reasonable steps to protect the information we receive from you, no computer system is completely secure, and we cannot be held liable for any loss you may suffer for unauthorized access or loss of any data provided to the Website.",
    privacy_9: "9. LINKS TO THIRD PARTY SITES",
    privacy_9_1: "The Website may contain links to third party websites and microsites, including third party payment gateways, whose privacy practices may differ from those of ours. If you submit personal data, including payment information, to any of those websites, that personal data is not subject to this Privacy Notice and will be subject to the third party's privacy practices instead.",
    privacy_9_2: "We therefore encourage you to review the privacy statement of any website you visit. By clicking on or activating such links and leaving the Website, we can no longer exercise control over any data or information which you give to any other entity after leaving the Website, and any access to such other websites is entirely at your own risk.",
    privacy_10: "10. WHAT RIGHTS DO I HAVE",
    privacy_10_1: "You have the right to ask us for a copy of your personal data; to correct, delete or restrict (stop any active) processing of your personal data; and to obtain the personal data you provide to us for a contract or with your consent in a structured, machine-readable format, and to ask us to share (port) this data to another data controller.",
    privacy_10_2: "In addition, you can object to the processing of your personal data in some circumstances (in particular, where we do not have to process the data to meet a contractual or other legal requirement, or where we are using the data for direct marketing).",
    privacy_10_3: "These rights may be limited, for example if fulfilling your request would adversely affect the rights and freedoms of a third party or if you ask us to delete information which we are required by law to keep or have compelling legitimate interests in keeping. Relevant exemptions are included in the applicable laws and regulations. We will inform you of relevant exemptions we rely upon when responding to any request you make.",
    privacy_10_4: "If you are based in the EU and have unresolved concerns, you have the right to complain to an EU data protection authority where you live, work or where you believe a breach may have occurred.",
    privacy_10_5: "You also have the right to define guidelines for the storage, erasure and disclosure of your personal data after your death.",
    privacy_10_6: 'You also have the right to register on an opt-out list from marketing via telephone (pursuant to L. 223-2 of the French Consumer Code).',
    privacy_10_7: 'To exercise any of these rights, or to obtain other information, such as a copy of a legitimate interests balancing test, you can get in touch with us – by contacting our Data Protection Officer (See "12. Contact us" below).  To correct your personal data, you can do so by signing into your user account and updating your personal data. We will only disclose personal data in accordance with an access request to the individual who registered his/her personal data with the Website, and only after confirming that the person requesting access is indeed the registered party.',
    privacy_10_8: "You may also contact our Data Protection Officer to withdraw your consent to our use of your personal data.",
    privacy_10_9: "Please allow one (1) month for your request to be processed.",
    privacy_11: "11. RETENTION OF INFORMATION",
    privacy_11_1: "Where we process registration data, we do this for as long as you are an active user of the Website and for six (6) years after this.",
    privacy_11_2: "Where we process personal data for marketing purposes or with your consent, we will process such data until you ask us to stop. We also keep a record of the fact that you have asked us not to send you direct marketing or not to process your data so that we can respect your request in the future.",
    privacy_11_3: "Where we process personal data in connection with performing a contract, we keep such data for six (6) years from your last interaction with us.",
    privacy_11_4: "If you withdraw your consent to our collection, use or disclosure of your personal data, we may not be able to provide you with services that require such personal data. Please also note that your personal data may still be retained on our database to the extent permitted by law.",
    privacy_12: "12. PRIVACY POLICY CHANGES",
    privacy_12_1: "If we decide to change our Privacy Notice for the Website, we will post the revised Privacy Notice here and, if appropriate, notify you by email. Please check back frequently to see any updates or changes to our Privacy Notice.",
    privacy_13: "13. CONTACT US – DPO CONTACT ",
    privacy_13_1: "To exercise any of your rights, or iIf you have any questions about this Privacy Notice or wish to obtain a copy of the relevant safeguard mechanism for transferring your personal data outside the EEA, please contact our Data Protection Officer at DPO@imperialtreasure.com. You can also raise a concern or lodge a complaint with a data protection authority or other official with jurisdiction.",
  },
  outlets: {
    outlets: "Participating Restaurants",
    restaurant_name: "Restaurant Name",
    address: "Address",
    contact: "Contact",
    email: "Email",
    outlets_1_1: "Imperial Treasure Fine Chinese Cuisine",
    outlets_1_2: "44 rue de Bassano, Paris, (inside Hotel La Clef Champs-Élysées Paris)",
    outlets_1_3: "33 (0) 1 58 56 29 13",
    outlets_1_4: "finechinese_paris@imperialtreasure.com",
  },
  privilege: {
    title: "PRIVILEGES",
    privilege: "MEMBERSHIP PRIVILEGES",
    privelege_txt: "Earn i$1 with every 10€ spent at participating restaurants.\nAccumulate i$50 to receive a 50€ e-voucher for use at participating restaurants.",
    silver: "SILVER",
    silver_1: "By invitation only",
    gold: "GOLD",
    gold_1: "Upgrade to GOLD tier when you spend 8888€* and above within the membership year.",
    gold_2: "Complimentary treats: \n1 x Peking Duck \n1 x Mid-Autumn Mooncake Set (Seasonal)",
    diamond: "DIAMOND",
    diamond_1: "Upgrade to DIAMOND tier when you spend 23888€* and above within the membership year.",
    diamond_1_1: "All of GOLD tier benefits plus:",
    diamond_2: "1 x 300€ e-voucher",
    diamond_3: "Complimentary treats: \n1 x Bottle of Champagne \n1 x Red Wine \n1 x Rose Lobster",
    service_charge_note: "*Does not include service charge (if any), VAT, other terms and conditions apply.",
    sign_up_now: "Sign up now",
  },
  resetPassword: {
    title: "Forget Password",
    reset_password: "Reset password",
    email: "*Please enter email",
    email_input: "Please enter email",
    email_errMsg: "Please enter valid email",
    mobile: "*Please enter mobile",
    mobile_errMsg: "Please enter valid phone",
    new_password: "*New password",
    new_password_errMsg1: "Please enter password",
    new_password_errMsg2: "Password must contain at least 8 alphanumeric characters and with at least one uppercase and one special character (?!@$%&*)",
    confirm_password: "*Confirm new password",
    confirm_password_errMsg: "Please confirm password",
    reset_password2: "Reset password",
    has_been_reset: "Password has been reset!",
    login: "Login",
  },
  forgetPassword: {
    title: "Forget Password",
    reset_password: "Reset password",
    forgot_password: "Forgot password?",
    reset_pw_method: "Please select password reset method",
    by_mobile: "By mobile",
    by_email: "By email",
    email: "*Please enter email",
    email_input: "Please enter email",
    email_errMsg: "Please enter valid email",
    mobile: "*Please enter mobile",
    mobile_errMsg: "Please enter valid phone",
    reset_password2: "Reset password",
    reset_password_sent_email: "Reset password link has been sent to your email.",
    reset_password_sent_mobile: "Reset password link has been sent to your mobile.",
    next: "Next",
    back: "Back",
  },
  updateEmail: {
    title: "Update Email",
    member_profile: "Member Profile",
    updateEmail: "Update Email",
    new_email: "*New Email Address",
    sent: "Sent",
    send_token: "Send Token",
    email_errMsg1: "Please enter valid email",
    email_errMsg2: "Email already registered",
    token: "*Token",
    token_error: "Token error",
    update: "Update",
    update_success: "Update Success!",
    done: "Done",
    Msg1: "Both mobile and email already used",
    Msg2: "Email already used",
  },
  updateMobile: {
    title: "Update Mobile",
    member_profile: "Member Profile",
    updateMobile: "Update Mobile",
    new_mobile: "*New Mobile No.",
    sent: "Sent",
    send_token: "Send Token",
    mobile_errMsg1: "Please enter valid phone",
    mobile_errMsg2: "Phone already registered",
    token: "*Token",
    token_error: "Token error",
    update: "Update",
    update_success: "Update Success!",
    done: "Done",
    Msg1: "Both mobile and email already used",
    Msg2: "Email already used",
  },
  coupon: {
    title: "Vouchers",
    member_profile: "Member Profile",
    coupon: "Vouchers",
    active: "Active",
    redeemed: "Redeemed",
    expired: "Expired",
    type_of_vouchers_opt1: "Please select type of vouchers",
    type_of_vouchers_opt2: "Type A",
    type_of_vouchers_opt3: "Type B",
    type_of_vouchers_opt4: "Type C",
    type_of_vouchers_opt5: "Type D",
    expiry_date: "Expiry Date:",
    redeemed_store: "Redeemed Outlet:",
    vaild_date: "Valid Date",
    description: "Description",
    vouchers_code: "Voucher Code",
    done: "Done",
    no_coupon_msg: "No coupons available",
  },
  editProfile: {
    title: "Edit Profile",
    member_profile: "Member Profile",
    editProfile: "Edit Profile",
    general: "General Information",
    security: "Security",
    subscriptions: "Subscriptions",
    salutation: "*Salutation",
    salutation_opt1: "Please select your salutation",
    salutation_opt2: "Mr",
    salutation_opt3: "Mrs",
    salutation_opt4: "Mdm",
    salutation_opt5: "Miss",
    salutation_opt6: "Dr",
    salutation_errMsg: "Please select salutation",
    gender: "*Gender",
    gender_opt1: "Please select gender",
    gender_opt2: "Male",
    gender_opt3: "Female",
    gender_errMsg: "Please select gender",
    first_name: "*Given Name",
    first_name_errMsg: "Please enter given name",
    last_name: "*Family Name",
    last_name_errMsg: "Please enter family name",
    birth: "*Date of Birth",
    mobile: "*Mobile No.",
    mobile_change: "Change",
    email: "*Email",
    email_change: "Change",
    language: "*Language",
    language_opt1: "Please select your language preference",
    language_opt2: "English",
    language_opt3: "Français",
    update: "Update",
    previous_password: "*Previous Password",
    previous_password_errMsg: "Previous password not correct",
    new_password: "*New Password",
    new_password_errMsg1: "Please enter password",
    new_password_errMsg2: "Password must contain at least 8 alphanumeric characters and with at least one uppercase and one special character (?!@$%&*)",
    confirm_password: "*Confirm Password",
    confirm_password_errMsg: "Please confirm password",
    subscriptions_txt1: "By checking the box(es) below, I give my consent to Imperial Treasure (France) (“ITRG”) to collect, use, store and/or disclose any personal data provided by me in connection with the Imperial Treasure Club membership (“Data”) for ITRG to share my Data with any of its associated or related companies or other third parties so that I may enjoy the benefits arising from my membership of the Imperial Treasure Club, including benefits arising from ITRG’s relationship with service providers within and outside of France. You can withdraw your consent at any time.",
    subscriptions_txt2: "I would like to receive marketing and promotional messages and materials from Imperial Treasure (France).",
    by_email: "By Email",
    by_mobile: "By Mobile",
    update_success: "Update Success!",
    done: "Done",
    password_update_success: "Password Update Success!",
  },
  profile: {
    title: "Member",
    logout: "Logout",
    click_to_renew: "CLICK TO RENEW​",
    activation_now: "ACTIVATE NOW",
    menber_id: "Member ID:",
    member: "member",
    active: "Active",
    expired: "Expired",
    salutation: "Salutation:",
    mobile: "Mobile No.:",
    birth: "Date of Birth:",
    password: "Password:",
    ellipsis: "········",
    change: "Change",
    gender: "Gender:",
    email: "Email:",
    cycle_start_date: "Tier Start Date:",
    first_joined_date: "First Joined Date:",
    optin_email: "Opt-in Email:",
    optin_sms: "Opt-in SMS:",
    cycle_expiry_date: "Cycle Expiry Date:",
    language_preference: "Language Preference:",
    spend: "€ Spend",
    expires_on: "  Expires on",
    spend_required: "Nett Spend required",
    to_upgrade_to: "to upgrade to",
    nett_spend_required: "Nett Spend required to maintain",
    nett: "Nett",
    to_maintain_to: "to maintain",
    silver_member: "Silver",
    gold_member: " Gold",
    diamond_member: " Diamond",
    total_nett_spend: "Total Nett Spend",
    current_membership: "in current membership year*",
    i_dollar: "i-Dollar",
    last_update: "  Last Update : ",
    i_dollar_earned: "i-Dollar Earned",
    i_dollar_converted: "i-Dollar Converted",
    i_dollar_balance: "i-Dollar Balance",
    i_dollar_automatically: "i$50 i-Dollars are automatically converted to 50€ Cash e-voucher",
    vouchers: "Vouchers",
    currently_active: "Currently Active",
    cash_vouchers: "Cash Vouchers",
    food_vouchers: "Food/Promotion Vouchers",
    click_to_view: "CLICK TO VIEW",
    transaction: "Transactions",
    this_month: "This Month",
    last_3_months: "Last 3 Months",
    last_12_months: "Last 12 Months",
    transaction_details: "Transaction Details",
    transaction_date: "Transaction Date",
    transaction_time: "Transaction Time",
    outlet: "Outlet",
    receipt: "Receipt No.",
    payment_method: "Payment Method",
    receipt_details: "Receipt Details",
    zero: ".0000",
    i_dollars_eared: "i-DOLLARS EARNED:",
    total: "Total :",
    done: "Done",
    thankyou_msg: "Thank you for dining with us!",
    activate_membership: "Activate membership",
    activate_membership_1: "Activate your membership to enjoy the following privileges:",
    activate_membership_2: "Earn i-Dollar (i$1 with every 10€ spent) on your spend at any participating restaurants. Accumulate i$50 to receive 50€ cash e-voucher for use at any participating restaurants.",
    activate_membership_3: "",
    activate_membership_4: "",
    activate_membership_5: "",
    activate_membership_6: "",
    activate_membership_7: "",
    renew_membership: "Renew Membership",
    renew_membership_1: "Click to Renew button for EXPIRED members: \n Your membership has expired. You can renew it now for $38 and receive the following e-vouchers during your new membership cycle.",
    renew_membership_2: "",
    renew_membership_3: "",
    renew_membership_4: "",
    confirm_to_renew: "Confirm To Renew",
    confirm_to_activate: "Confirm To Activate",
    change_password: "Change Password",
    previous_password: "*Previous Password",
    previous_password_errMsg: "Previous password not correct",
    new_password: "*New Password",
    new_password_errMsg1: "Please enter & confirm password",
    new_password_errMsg2: "Password must contain at least 8 alphanumeric characters and with at least one uppercase and one special character (?!@$%&*)​",
    confirm_password: "*Confirm Password",
    close: "X CLOSE",
    member_activated: "Member activated",
    password_updated: "Password updated",
    view_more: "VIEW MORE",
    add_friend: "ADD",
    silver_tier: "Silver ",
    gold_tier: "Gold ",
    diamond_tier: "Diamond ",
    spend_date_format: "Expired year",
  },
  transactionDetails: {
    transaction_date: "Transaction Date",
    outlet: "Outlet",
    receipt_no: "Check/Receipt No",
    amount_spend: "Total Paid",
    amount_qualified_for_i_dollar: "Amount Qualified For i-Dollar",
    i_dollars: "i-Dollars",
    selected_start_date: "Selected start date",
    selected_end_date: "Selected end date",
    no_tranMsg: "No transaction records during the selected period.",
    action: "Action",
    apply: "Apply",
    view: "View",
  },
  contactUs: {
    title: "Contact Us",
    title_message: "Please complete and submit your feedback below and we will get back to you within 3-5 working days.",
    firstname: "Given Name",
    firstname_errMsg: "Please fill in your given name",
    lastname: "Family Name",
    lastname_errMsg: "Please fill in your family name",
    emailAddress: "Email Address",
    emailAddress_errMsg: "Please enter valid email",
    mobile: "Mobile No.",
    mobile_errMsg: "Please enter valid phone",
    type: "Type",
    type_opt1: "Please enter your feedback message type :",
    type_opt2: "Options",
    type_opt3: "Enquiry",
    type_opt4: "Feedback",
    type_opt5: "Newsletter Subscription",
    type_errMsg: "Please enter your feedback message type",
    message: "Message",
    message_errMsg: "Please enter your feedback message",
    captcha: "Captcha",
    captcha_errMsg: "Captcha incorrect",
    feedback_sucMsg: "Thank you for reaching out and providing us with valuable feedback. \nWe will respond to you very soon.",
    submit: "Submit",
  },
  languageSetting: {
    title: "Language Setting",
    setting: "Setting",
    language_preference: "Language Preference",
    language_preference_opt1: "Please select your language preference",
    language_preference_opt2: "English",
    language_preference_opt3: "Français",
    language_preference_errMsg: "Please select language preference",
    submit: "Submit",
  },
};