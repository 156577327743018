import React, { useState, useEffect, useRef } from "react";
import {
  View,
  ScrollView,
  Text,
  Image,
  TextInput,
  TouchableOpacity,
  SafeAreaView,
  ActivityIndicator,
  Modal,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import MobileHeaderNoBackBtn from "../components/mHeaderOnly";
import Footer from "../components/footer";
import InsideHead from "../components/insideHeader";
import AsyncStorage from "../components/AsyncStorage";
import axios from "axios";
import { API_MIDDLEWARE, API_REGION } from "@env";
import moment from "moment";
import { WebView } from "react-native-webview";
import i18n from "../components/i18n";
import TransactionDetails from "./transactionDetails";
import CookieConsent from "../components/CookieConsent";
import NewMobileHeader from "../components/newMobileHeader";

const Profile = () => {
  const navigation = useNavigation();

  const [activiateOverlay, setActiviateOverlay] = useState(false);
  const [renewOverlay, setRenewOverlay] = useState(false);
  const [memberCode, setMemberCode] = useState("");
  const [memberRegion, setMemberRegion] = useState("");
  const [member, setMember] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [passwordOverlay, setPasswordOverlay] = useState(false);
  const [pPassword, setPPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [pPasswordErr, setPPasswordErr] = useState(false);
  const [updatePasswordErr, setUpdatePasswordErr] = useState(false);
  const [spendingSlide, setSpendingSlide] = useState({
    spendingTotal: 15,
    spendingCount: 1,
    spendingPoint: 10,
  });
  const [couponStatus, setCouponStatus] = useState({
    issued: 0,
    used: 0,
    expired: 0,
  });
  const [memberStatus, setMemberStatus] = useState("");
  const [paymentWebview, setPaymentWebview] = useState(false);
  const [paymentURL, setPaymentURL] = useState("");
  const [paymentDetail, setPaymentDetail] = useState({
    invoiceNo: "",
    desc: "",
    amount: "",
  });
  const [snackbarActiviated, setSnackbarActiviated] = useState(false);
  const [pointsRecord, setPointsRecord] = useState({
    balance: 0,
    converted: 0,
    earned: 0,
    last_update: "",
  });
  const [snackbarPasswordUpdated, setSnackbarPasswordUpdated] = useState(false);
  const [passwordFormatErr, setPasswordFormatErr] = useState(false);
  const [couponCount, setCouponCount] = useState({ cash: 0, food: 0 });
  const [spendDateDisplay, setspendDateDisplay] = useState(true);
  const [currentTierSpent, setCurrentTierSpent] = useState(0);
  const [outstandingAmt, setOutstandingAmt] = useState(0);
  const [osAmtToDiamond, setOsAmtToDiamond] = useState(0);
  const [isShowRenewBtn, setIsShowRenewBtn] = useState(false);
const [buttonLock, setButtonLock] = useState(false)

  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;

    spendCardDateFormat();

    AsyncStorage.getData("mCode").then((value) => {
      if (!value) {
        navigation.push("Login");
      } else {
        let loginDetail = JSON.parse(value);
        setMemberCode(loginDetail.code);
        setMemberRegion(loginDetail.region);

        let data = {
          code: loginDetail.code,
          username: loginDetail.username,
          region: loginDetail.region,
        };

        customerGet(data, (customer) => {
          console.log("Customer info :", customer);

          customer.spend_required = parseInt(customer.spend_required);
          customer.spend_this_tier = parseInt(customer.spend_this_tier);

          setMember(customer);

          let spending = spendingSlide;
          console.log("Spending details :", spending);
          let totalSpending =
            customer.spend_required + customer.spend_this_tier;
          // console.log("totalSpending :", totalSpending)
          let slidePercent = parseInt(
            (customer.spend_this_tier / totalSpending) * 100
          );
          console.log("slidePercent :", slidePercent);

          if (slidePercent > 0) {
            spending.spendingTotal = slidePercent + 1;
          } else {
            spending.spendingTotal = 0;
          }

          if (slidePercent > 5) {
            spending.spendingPoint = slidePercent;
          } else {
            spending.spendingPoint = 0;
          }

          if (slidePercent > 15) {
            spending.spendingCount = slidePercent + 15;
          }

          let couponData = {
            code: loginDetail.code,
            offset: 0,
            limit: 9999,
            region: loginDetail.region,
          };

          // couponGet(couponData, (coupons) => {
            //   setCouponStatus({
              //     issued: coupons.general?.ISSUED,
              //     used: coupons.general?.USED,
              //     expired: coupons.general?.EXPIRED,
            //   });

            //   let cash = 0;
            //   let food = 0;

            //   console.log("coupon check:", coupons.coupons.filter(c => c.status == "ISSUED").length)

          //   coupons.coupons.map((item, index) => {
              //     if (item.status == "ISSUED") {
                //       if (item.category == "Food") {
                  //         food++;
                //       }

                //       if (item.category == "Cash") {
                  //         cash++;
          //       }
          //     }
          //   });

          //   setCouponCount({
          //     food: food,
          //     cash: cash,
          //   });
          // });

          let couponSummary = {
            code: loginDetail.code,
            region: loginDetail.region
          }

          console.log("get coupon summary")

          CouponGetSummary(couponSummary, (coupons) => {
            setCouponStatus({
              issued: coupons.active,
              used: 0,
              expired: 0
            });

            setCouponCount({
              food: coupons.other_active,
              cash: coupons.cash_active
            })
          })

          let pointsData = {
            code: loginDetail.code,
            region: loginDetail.region,
          };

          pointsGet(pointsData, (points) => {
            console.log("points :", points);
            setPointsRecord({
              balance: points.balance,
              converted: points.converted,
              earned: points.earned,
              last_update: points.last_update,
            });
          });

          // check outstanding amount for maintaining current tier:
          let amtData = {
            code: loginDetail.code,
            region: loginDetail.region,
          };

          RenewAmtGet(amtData, (amt) => {
            console.log("amt : ", amt)
            let currentTierAmt = parseInt(amt.amount);
            let amtSpentInCurrentYear = parseInt(customer.spend_this_tier);
            console.log("amtSpentInCurrentYear :", amtSpentInCurrentYear);

            setCurrentTierSpent(amtSpentInCurrentYear);

            // console.log("currentTierSpent: ", currentTierSpent)

            if (amtSpentInCurrentYear >= currentTierAmt) {
              setOutstandingAmt(0);
            } else {
              let osAmtToMaintainCurrentTier =
                currentTierAmt - amtSpentInCurrentYear;
              console.log(
                "osAmtToMaintainCurrentTier :",
                osAmtToMaintainCurrentTier
              );

              setOutstandingAmt(currencyFormat(osAmtToMaintainCurrentTier));
            }
          });

          // To return an o/s amt for upgrading to Diamond:
          let customerTier = customer.tier;
          let currentTierSpent = customer.spend_this_tier;

          osAmtToDiamondTier(customerTier, currentTierSpent);

          checkMemberState(customer.memberstatus, customer.expirydate);

          showRenewBtn(customer.memberstatus, customer.expirydate);

          setIsLoading(false);
        });
      }
    });

    return () => {
      mounted.current = false;
    };
  }, []);

  const checkChange = (val) => {
    setRowSelect(val);
    console.log(val);
  };

  const checkMemberState = (memberStatus, expirydate) => {
    if (memberStatus == null || memberStatus == "null" || memberStatus == "") {
      setMemberStatus("expired");
    } else if (memberStatus == "ACTIVATED" || memberStatus == "CREATED") {
      setMemberStatus("active");

      let currentDate = moment();
      let expiriedDate = moment(expirydate);
      let difference = currentDate.diff(expiriedDate, "seconds");

      console.log(difference < 0);

      if (expirydate == null || expirydate == "null" || expirydate == "") {
        setMemberStatus("expired");
        // console.log("checkMemberState : expired");
      } else {
        if (difference < 0) {
          setMemberStatus("active");
          // console.log("checkMemberState : active");
        } else {
          setMemberStatus("expired");
          // console.log("checkMemberState : expired");
        }
      }
    } else if (memberStatus == "EXPIRED") {
      setMemberStatus("expired");
    }
  };

  const showRenewBtn = (memberStatus, expirydate) => {
    if (memberStatus == "ACTIVATED" || memberStatus == "CREATED") {
            const currentDate = moment();
      const expiriedDate = moment(expirydate);
      const differenceInMonths = expiriedDate.diff(currentDate, "months", true);

      if (differenceInMonths <= 1 && differenceInMonths >= 0) {
        setIsShowRenewBtn(true);
      } else {
        setIsShowRenewBtn(false);
      }
} else if (memberStatus == "EXPIRED") {
      setIsShowRenewBtn(false);
    }
  };

  const confirmActive = () => {
    
    setIsLoading(true)

    if (!buttonLock) {
      setButtonLock(true)

      let data = {
        code: memberCode
      }

      getPaymentURL(data, (pay) => {
      console.log(pay);
      setPaymentURL(pay.url);

      let payD = paymentDetail;
      payD.invoiceNo = pay.invoiceno;
      payD.desc = pay.description;
      payD.amount = pay.amount;

      setRenewOverlay(false);
      setActiviateOverlay(false);
      setPaymentDetail(payD);
      setPaymentWebview(true);
setIsLoading(false)
        setButtonLock(false)
    });
}
  };

  // To check with relevant invoice after payment success:
  const CheckPayment = () => {
    // setIsLoading(true);

    let data = {
      invoiceNo: paymentDetail.invoiceNo,
    };

    console.log("checkPayment-data :", data); // return an invoice number

    checkPaymentResult(data, (result) => {
      console.log("result :", result);
      console.log("result.respCode :", result.respCode);

      if (result.respCode == "0000") {
        // invoice payment success handling:
        let actFnc = null;

        if (memberStatus == "active") {
          actFnc = renewMember;
          console.log("renew member success");
        }

        if (memberStatus == "expired" && member.tier == null) {
          // setIsLoading(true);

          let data2 = {
            code: member.code,
          };
          // console.log("data2 :", data2);

          confirmCustomer(data2, (customer) => {
            // console.log("memberStatus-customer :", customer)
            if (customer.status == "success") {
              // console.log("member :", member)
              let store = {
                code: member.code,
                username:
                  member.country_code + member.mobile.replace(/\s/g, ""),
                region: API_REGION,
              };
              console.log("confirmCustomer-store :", store);
              AsyncStorage.storeData("mCode", JSON.stringify(store));
              setIsLoading(false);
              navigation.push("Profile");
            } else {
              console.log("Confirm Customer failure");
              // alert(customer.message);
              setIsLoading(false);
            }
          });
        }

        if (memberStatus == "expired") {
          actFnc = reactivateMember;
          console.log("reactive member success");
        }

        let data = {
          code: member.code,
        };
        console.log("member.code :", member.code);

        actFnc(data, (member) => {
          console.log("Renew/Reactive success");
// location.reload so that user can get the latest updates if renew api no issue
          location.reload();
        });
      } else {
        // payment failure
        setIsLoading(false);
        alert("Payment failed - " + result.respDesc);
location.reload();
      }
    });
  };

  const checkPaymentResult = (data, callback) => {
    let formData = new FormData();

    formData.set("method", "paymentChk");
    formData.set("region", API_REGION);
    formData.set("invoiceno", data.invoiceNo);

    console.log("checkPaymentResult-formData before :", formData);

    axios
      .post(API_MIDDLEWARE, formData)
      .then(function (response) {
        callback(response.data);
      })
      .catch(function (error) {
        callback(false);
      });
  };

  const confirmCustomer = (data, callback) => {
    let formData = new FormData();
    formData.append("method", "confirmCust");
    formData.append("code", data.code);
    formData.append("region", API_REGION);

    axios
      .post(API_MIDDLEWARE, formData)
      .then(function (response) {
        callback(response.data);
      })
      .catch(function (error) {
        callback(false);
      });
  };

  const confirmUpdatePassword = () => {
    let errors = [];
    setPPasswordErr(false);
    setUpdatePasswordErr(false);
    setPasswordFormatErr(false);

    if (pPassword == "") {
      console.log("pPassword error");
      errors.push("err");
      setPPasswordErr(true);
    }

    if (password == "") {
      errors.push("err");
      console.log("Password error");
      setUpdatePasswordErr(true);
    } else if (password != confirmPassword) {
      errors.push("err");
      console.log("Confirm error");
      setUpdatePasswordErr(true);
    } else if (
      !password.match(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#^-])[A-Za-z\d@$!%*?&#^-]{8,}$/
      )
    ) {
      errors.push("err");
      setPasswordFormatErr(true);
    }

    if (!errors.length) {
      setIsLoading(true);

      let loginData = {
        mobile: member.country_code + member.mobile,
        password: pPassword,
      };

      loginCheck(loginData, (check) => {
        console.log("loginCheck :", check);

        if (check.status != "success") {
          console.log("login error");
          setPPasswordErr(true);
          setIsLoading(false);
        } else {
          let updateData = {
            code: member.code,
            password: password,
          };

          updatePassword(updateData, (update) => {
            if (update.status == "success") {
              setIsLoading(false);
              setPasswordOverlay(false);
              setPPassword("");
              setPassword("");
              setConfirmPassword("");
              setSnackbarPasswordUpdated(true);
            } else {
              console.log(update);
            }
          });
        }
      });
    }
  };

  const gender = (val) => {
    switch (val) {
      case "mr":
      case "dr":
        return "Male";
        break;
      case "mrs":
      case "miss":
        return "Female";
        break;
    }
  };

  const goCouponPage = () => {
    navigation.push("Coupon");
  };

  const getPaymentURL = (data, callback) => {
    //https://webform-uat.xgate.com/webapi/imperial/middleware.php?method=payment&region=SG

    let formData = new FormData();
    formData.set("method", "payment");
    formData.set("region", memberRegion);
    formData.set("type", "renew");
formData.set("code", data.code);

    axios
      .post(API_MIDDLEWARE, formData)
      .then(function (response) {
        callback(response.data);
      })
      .catch(function (error) {
        callback(false);
      });
  };

  const customerGet = (data, callback) => {
    let formData = new FormData();
    formData.append("method", "getCust");
    formData.append("code", data.code);
    formData.append("username", data.username);
    formData.append("region", data.region);

    axios
      .post(API_MIDDLEWARE, formData)
      .then(function (response) {
        callback(response.data);
      })
      .catch(function (error) {
        callback(false);
      });
  };

  const couponGet = (data, callback) => {
    let formData = new FormData();
    formData.append("method", "getCoupon");
    formData.set("code", data.code);
    formData.set("offset", data.offset);
    formData.set("limit", data.limit);
    formData.append("region", data.region);

    axios
      .post(API_MIDDLEWARE, formData)
      .then(function (response) {
        callback(response.data);
      })
      .catch(function (error) {
        callback(false);
      });
  };

  const CouponGetSummary = (data, callback) => {
    let formData = new FormData();
    formData.append("method", "getCouponSummary");
    formData.append("code", data.code);
    formData.append("region", data.region);

    axios
      .post(API_MIDDLEWARE, formData)
      .then(function (response) {
        callback(response.data);
      })
      .catch(function (error) {
        callback(false);
      });
  };

  const pointsGet = (data, callback) => {
    let formData = new FormData();
    formData.append("method", "getPoints");
    formData.append("code", data.code);
    formData.append("region", data.region);

    axios
      .post(API_MIDDLEWARE, formData)
      .then(function (response) {
        callback(response.data);
      })
      .catch(function (error) {
        callback(false);
      });
  };

  const loginCheck = (data, callback) => {
    let formData = new FormData();
    formData.append("method", "login");
    formData.append("mobile", data.mobile);
    formData.append("password", data.password);
    formData.append("region", memberRegion);

    axios
      .post(API_MIDDLEWARE, formData)
      .then(function (response) {
        callback(response.data);
      })
      .catch(function (error) {
        console.log(error);
        callback(false);
      });
  };

  const updatePassword = (data, callback) => {
    let formData = new FormData();
    formData.append("method", "updateCust");
    formData.append("code", data.code);
    formData.append("customer_password", data.password);
    formData.append("region", memberRegion);

    axios
      .post(API_MIDDLEWARE, formData)
      .then(function (response) {
        callback(response.data);
      })
      .catch(function (error) {
        callback(false);
      });
  };

  const renewMember = (data, callback) => {
    let formData = new FormData();
    formData.append("method", "renew");
    formData.append("code", data.code);
    formData.append("region", memberRegion);

    console.log("renewMember-formData :", formData);

    axios
      .post(API_MIDDLEWARE, formData)
      .then(function (response) {
        callback(response.data);
      })
      .catch(function (error) {
        callback(false);
      });
  };

  const reactivateMember = (data, callback) => {
    let formData = new FormData();
    formData.append("method", "reactivate");
    formData.append("code", data.code);
    formData.append("region", memberRegion);

    axios
      .post(API_MIDDLEWARE, formData)
      .then(function (response) {
        callback(response.data);
      })
      .catch(function (error) {
        callback(false);
      });
  };

  const dateFormat = (date) => {
    if (date && date != "N/A") {
      return moment(date).format("DD/MM/YYYY");
    } else {
      return "N/A";
    }
  };

  const birthDateFormat = (date) => {
    if (date && date != "N/A") {
      return moment(date).format("DD-MMM-YYYY");
    } else {
      return "N/A";
    }
  };

  const optinStatus = (status) => {
    if (status == 1) {
      return "Yes";
    } else {
      return "No";
    }
  };

  const capitalize = (string) => {
    if (string) {
      return string.replace(/^./, (str) => str.toUpperCase());
    } else {
      return "";
    }
  };

  const viewMemberTier = (tier) => {
    if (tier) {
      if (tier.toLowerCase() == "silver") {
        return i18n.t("profile.silver_tier");
      } else if (tier.toLowerCase() == "gold") {
        return i18n.t("profile.gold_tier");
      } else if (tier.toLowerCase() == "diamond") {
        return i18n.t("profile.diamond_tier");
      } else {
        return tier;
      }
    }
  };

  const nextTier = (tier) => {
    console.log("tier:", tier);
    if (tier) {
      if (tier.toLowerCase() == "silver") {
        return i18n.t("profile.gold_member");
      } else if (tier.toLowerCase() == "gold") {
        return i18n.t("profile.diamond_member");
      } else if (tier.toLowerCase() == "diamond") {
        return i18n.t("profile.diamond_member");
      } else {
        return tier;
      }
    }
  };

  const currentTier = (tier) => {
    if (tier) {
      if (tier.toLowerCase() == "silver") {
        return i18n.t("profile.silver_member");
      } else if (tier.toLowerCase() == "gold") {
        return i18n.t("profile.gold_member");
      } else if (tier.toLowerCase() == "diamond") {
        return i18n.t("profile.diamond_member");
      } else {
        return tier;
      }
    }
  };

  const RenewAmtGet = (data, callback) => {
    let formData = new FormData();
    formData.append("method", "getRenew");
    formData.append("code", data.code);
    formData.append("region", data.region);

    axios
      .post(API_MIDDLEWARE, formData)
      .then(function (response) {
        callback(response.data);
      })
      .catch(function (error) {
        callback(false);
      });
  };

  // Silver: 88
  // Gold:10888
  // Diamond:28888

  // Silver -> Diamond = 28888 - (88 - [currentTierSpent])
  // Gold -> Diamond = 28888 - (10888 - [currentTierSpent])

  const osAmtToDiamondTier = (tier, currentTierSpent) => {
    console.log("tier check :", tier);
    // console.log("Amount spent in current year :", currentTierSpent);

    let amtSpentInCurrentYear = currentTierSpent;
    console.log("amtSpentInCurrentYear : ", amtSpentInCurrentYear);

    if (tier) {
      if (tier.toLowerCase() == "silver") {
        setOsAmtToDiamond(parseInt(28888 - amtSpentInCurrentYear));
      } else if (tier.toLowerCase() == "gold") {
        setOsAmtToDiamond(parseInt(28888 - amtSpentInCurrentYear));
      } else {
        setOsAmtToDiamond(0);
      }
    }
  };

  const currencyFormat = (str) => {
    if (str) {
      str = str.toString();
      return str.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    } else {
      return "";
    }
  };

  const spendCardDateFormat = () => {
    if (i18n.locale == "en") {
      setspendDateDisplay(true);
    } else {
      setspendDateDisplay(false);
    }
  };

  return (
    <SafeAreaView
      className="min-h-full bg-[#F5F5F5]"
      pointerEvents={!isLoading ? "auto" : "none"}
    >
      {isLoading && (
        <View
          className="left-0 right-0 top-0 bottom-0 justify-center items-center bg-[#f3f3f394] z-10 w-full h-full"
          style={{ position: "fixed" }}
          pointerEvents={"none"}
        >
          <ActivityIndicator size="large" color={"#BD9B60"} />
        </View>
      )}
      <InsideHead />
      <NewMobileHeader />
      <MobileHeaderNoBackBtn title={i18n.t("profile.title")} />
      <ScrollView className="flex-1 h-auto">
        <View className="w-full md:w-4/5 max-w-7xl m-auto mt-3 pr-4 hidden md:flex">
          <TouchableOpacity
            onPress={() => {
              navigation.push("Login");
            }}
          >
            <Text className="text-brandgold w-auto h-8 leading-8 font-normal text-right font-PingFangSC">
              {i18n.t("profile.logout")}
            </Text>
          </TouchableOpacity>
        </View>

        {/* Client Details Area */}
        <View className="w-full md:w-4/5 max-w-7xl m-auto md:bg-white p-4 md:p-5 md:mt-3 flex flex-row relative mt-6">
          <View className="w-full">
            <View className="w-11/12 flex flex-row justify-start content-center items-center mb-2">
              <Text className="text-2xl md:text-3xl text-brandgold md:text-black font-semibold font-Crimson">
                {member.first_name} {member.last_name}
              </Text>

              <TouchableOpacity
                className="px-4"
                onPress={() => navigation.push("EditProfile")}
              >
                <Image
                  className="w-[15px] h-[16px]"
                  source={require("../assets/icons/icon-edit.png")}
                />
              </TouchableOpacity>

              {/* member's status. */}
              {/* {isShowRenewBtn &&
                member.tier == "Silver" &&
                member.show_renew == "true" && (
                  <>
                    <TouchableOpacity
                      className="w-auto h-8 max-h-8 bg-brandgold px-4 ml-5"
                      onPress={() => setRenewOverlay(true)}
                    >
                      <Text className="text-white w-full h-8 leading-8 text-center font-normal font-PingFangSC">
                        {i18n.t("profile.click_to_renew")}​
                      </Text>
                    </TouchableOpacity>
                  </>
                )} */}

              {/* {memberStatus == "expired" &&
                (member.tier == null || member.tier == "") &&
                member.show_renew == "true" && (
                  <>
                    <TouchableOpacity
                      className="w-auto h-8 max-h-8 bg-brandgold px-4 ml-5"
                      onPress={() => setActiviateOverlay(true)}
                    >
                      <Text className="text-white w-full h-8 leading-8 text-center font-normal font-PingFangSC">
                        {i18n.t("profile.activation_now")}​
                      </Text>
                    </TouchableOpacity>
                  </>
                )} */}
            </View>

            <Text className="text-[10px] font-normal text-[#494949] md:hidden">
              {i18n.t("profile.menber_id")} {member.code}
            </Text>

            <View className="hidden md:flex flex-row font-PingFangSC">
              {memberStatus == "active" && (
                <>
                  <Text className="text-sm text-brandgold py-0 px-2 bg-[#bd9b6036] rounded-r-sm mr-2">
                    {viewMemberTier(member.tier)}
                    {i18n.t("profile.member")}
                  </Text>
                  <Text className="text-sm text-brandgold py-0 px-2 bg-[#bd9b6036] rounded-r-sm mr-2">
                    {i18n.t("profile.active")}
                  </Text>
                </>
              )}
              {memberStatus == "expired" && (
                <>
                  <Text className="text-sm text-[#111111] py-0 px-2 bg-[#F2F3F5] rounded-r-sm mr-2">
                    {i18n.t("profile.expired")}
                  </Text>
                </>
              )}
            </View>

            {/* User's info. */}
            <View
              className="hidden md:flex flex-row justify-start content-start md:justify-between
            lg:justify-start flex-wrap mt-5"
            >
              <View className="min-w-[250px] max-w-[285px] w-1/4 flex flex-row my-2">
                <Text className="w-32 text-sm font-semibold text-[#86909C] font-NunitoSans">
                  {i18n.t("profile.salutation")}
                </Text>
                <Text className="text-sm font-semibold text-[#111111] pr-2 font-NunitoSans">
                  {capitalize(member.title)}
                </Text>
              </View>
              <View className="min-w-[250px] max-w-[285px] w-1/4 flex flex-row my-2">
                <Text className="w-32 text-sm font-semibold text-[#86909C] font-NunitoSans">
                  {i18n.t("profile.menber_id")}
                </Text>
                <Text className="text-sm font-semibold text-[#111111] pr-2 font-NunitoSans">
                  {member.code}
                </Text>
              </View>
              <View className="min-w-[250px] max-w-[285px] w-1/4 flex flex-row my-2">
                <Text className="w-32 text-sm font-semibold text-[#86909C] font-NunitoSans">
                  {i18n.t("profile.mobile")}
                </Text>
                <Text className="text-sm font-semibold text-[#111111] pr-2 font-NunitoSans">
                  +{member.country_code}
                  {member.mobile}
                </Text>
              </View>
              <View className="min-w-[250px] max-w-[285px] w-1/4 flex flex-row my-2">
                <Text className="w-32 text-sm font-semibold text-[#86909C] font-NunitoSans">
                  {i18n.t("profile.birth")}
                </Text>
                <Text className="text-sm font-semibold text-[#111111] pr-2 font-NunitoSans">
                  {birthDateFormat(member.birthday)}
                </Text>
              </View>
              <View className="min-w-[250px] max-w-[285px] w-1/4 flex flex-row my-2">
                <Text className="w-32 text-sm font-semibold text-[#86909C] font-NunitoSans">
                  {i18n.t("profile.password")}
                </Text>
                <Text className="text-sm font-semibold text-[#111111] pr-2 font-NunitoSans">
                  {i18n.t("profile.ellipsis")}
                </Text>
                <TouchableOpacity onPress={() => setPasswordOverlay(true)}>
                  <Text className="text-brandgold font-NunitoSans">
                    {i18n.t("profile.change")}
                  </Text>
                </TouchableOpacity>
              </View>
              <View className="min-w-[250px] max-w-[285px] w-1/4 flex flex-row my-2">
                <Text className="w-32 text-sm font-semibold text-[#86909C] font-NunitoSans">
                  {i18n.t("profile.gender")}
                </Text>
                <Text className="text-sm font-semibold text-[#111111] pr-2 font-NunitoSans">
                  {capitalize(member.gender)}
                </Text>
              </View>
              <View className="min-w-[500px] max-w-[570px] w-1/4 flex flex-row my-2">
                <Text className="w-32 text-sm font-semibold text-[#86909C] font-NunitoSans">
                  {i18n.t("profile.email")}
                </Text>
                <Text className="text-sm font-semibold text-[#111111] pr-2 font-NunitoSans">
                  {member.email}
                </Text>
              </View>
              <View className="min-w-[250px] max-w-[285px] w-1/4 flex flex-row my-2">
                <Text className="w-32 text-sm font-semibold text-[#86909C] font-NunitoSans">
                  {i18n.t("profile.cycle_start_date")}
                </Text>
                <Text className="text-sm font-semibold text-[#111111] pr-2 font-NunitoSans">
                  {dateFormat(member.cyclestartdate)}
                </Text>
              </View>
              <View className="min-w-[250px] max-w-[285px] w-1/4 flex flex-row my-2">
                <Text className="w-32 text-sm font-semibold text-[#86909C] font-NunitoSans">
                  {i18n.t("profile.cycle_expiry_date")}
                </Text>
                <Text className="text-sm font-semibold text-[#111111] pr-2 font-NunitoSans">
                  {dateFormat(member.expirydate)}
                </Text>
              </View>
              <View className="min-w-[250px] max-w-[285px] w-1/4 flex flex-row my-2">
                <Text className="w-32 text-sm font-semibold text-[#86909C] font-NunitoSans">
                  {i18n.t("profile.first_joined_date")}
                </Text>
                <Text className="text-sm font-semibold text-[#111111] pr-2 font-NunitoSans">
                  {dateFormat(member.regdate)}
                </Text>
              </View>
              <View className="min-w-[250px] max-w-[285px] w-1/4 flex flex-row my-2">
                <Text className="w-32 text-sm font-semibold text-[#86909C] font-NunitoSans">
                  {i18n.t("profile.language_preference")}
                </Text>
                <Text className="text-sm font-semibold text-[#111111] pr-2 font-NunitoSans">
                  {capitalize(member.language).toUpperCase()}
                </Text>
              </View>
              <View className="min-w-[250px] max-w-[285px] w-1/4 flex flex-row my-2">
                <Text className="w-32 text-sm font-semibold text-[#86909C] font-NunitoSans">
                  {i18n.t("profile.optin_sms")}
                </Text>
                <Text className="text-sm font-semibold text-[#111111] pr-2 font-NunitoSans">
                  {optinStatus(member.mobile_optin)}
                </Text>
              </View>
              <View className="min-w-[250px] max-w-[285px] w-1/4 flex flex-row my-2">
                <Text className="w-32 text-sm font-semibold text-[#86909C] font-NunitoSans">
                  {i18n.t("profile.optin_email")}
                </Text>
                <Text className="text-sm font-semibold text-[#111111] pr-2 font-NunitoSans">
                  {optinStatus(member.email_optin)}
                </Text>
              </View>
            </View>
          </View>
        </View>

        {/* Profile Mobile UI */}
        <View className="w-full md:w-4/5 px-4 md:p-0 max-w-7xl m-auto flex-wrap justify-between md:mt-4 md:flex md:flex-row">
          <View className="w-full md:w-[32%] h-auto bg-white p-5 min-h-[200px] mb-2 md:mb-0">
            {/* Mobile UI member's status shown */}
            <View className="md:hidden flex-row font-PingFangSC mb-2">
              {memberStatus == "active" && (
                <>
                  <Text className="text-sm text-brandgold py-1 px-2 bg-[#bd9b6036] rounded-r-sm mr-4">
                    {viewMemberTier(member.tier)}
                    {i18n.t("profile.member")}
                  </Text>
                  <Text className="text-sm text-brandgold py-1 px-2 bg-[#bd9b6036] rounded-r-sm mr-2">
                    {i18n.t("profile.active")}
                  </Text>
                </>
              )}
              {memberStatus == "expired" && (
                <>
                  <Text className="text-sm text-[#111111] py-0 px-2 bg-[#F2F3F5] rounded-r-sm mr-2">
                    {i18n.t("profile.expired")}
                  </Text>
                </>
              )}
            </View>

            {/* Spending Area */}
            <View className="flex flex-row flex-wrap items-center justify-start">
              <Text className="text-xl font-semibold text-black font-Crimson">
                {i18n.t("profile.spend")}​
              </Text>

              {spendDateDisplay && (
                <Text className="text-sm font-semibold text-[#86909C] font-NunitoSans">
                  {" "}
                  {i18n.t("profile.expires_on")} {dateFormat(member.expirydate)}
                </Text>
              )}

              {spendDateDisplay == false && (
                <Text className="text-sm font-semibold text-[#86909C] font-NunitoSans">
                  {" "}
                  {dateFormat(member.expirydate)}{" "}
                  {i18n.t("profile.spend_date_format")}
                </Text>
              )}
            </View>

            {/* Spend slider */}
            <View className="relative my-6">
              <View className="absolute top-0 left-0 w-full h-3 bg-[#EBEAED] rounded-r-[32px] rounded-l-[32px]">
                <View
                  className="absolute top-[2px] left-[2px] w-4/5 h-2 bg-brandgold rounded-r-[32px] rounded-l-[32px]"
                  style={{ width: spendingSlide.spendingTotal + "%" }}
                ></View>
                {/* <Text
                  className="top-[1px] absolute text-[7px] text-white"
                  style={{ left: spendingSlide.spendingCount + "%" }}
                >
                  S${member.spend_this_tier}
                </Text> */}
                <View
                  className="top-[-2px] absolute w-4 h-4 bg-brandgold rounded-full"
                  style={{ left: spendingSlide.spendingPoint + "%" }}
                ></View>
                <Text className="top-[1px] right-[17px] absolute text-[7px] text-[#84818A]">
                  {member.spend_this_tier + member.spend_required}€
                </Text>
                <View className="top-[-2px] right-0 absolute w-4 h-4 bg-[#EBEAED] rounded-full"></View>
              </View>
            </View>

            <View className="flex flex-row flex-wrap items-start justify-start">
              <View className="w-1/2">
                <Text className="text-xl font-semibold text-black font-Crimson">
                  {member.spend_this_tier
                    ? currencyFormat(member.spend_this_tier)
                    : "0"}
                  €
                </Text>
                <Text className="text-xs font-normal text-[#84818A] font-NunitoSans">
                  {i18n.t("profile.total_nett_spend")}
                </Text>
                <Text className="text-[10px] font-light text-[#84818A] font-NunitoSans">
                  {i18n.t("profile.current_membership")}
                </Text>
              </View>

              <View className="w-1/2">
                {member.tier == "Diamond" ? (
                  <View>
                    <Text className="text-xl font-semibold text-black font-Crimson">
                      {outstandingAmt}€
                    </Text>

                    <Text className="text-xs font-normal text-[#84818A] font-NunitoSans">
                      {i18n.t("profile.nett_spend_required")}
                      {currentTier(member.tier)}
                    </Text>
                  </View>
                ) : (
                  <View>
                    <Text className="text-xl font-semibold text-black font-Crimson">
                      {member.spend_required
                        ? currencyFormat(member.spend_required)
                        : "0"}
                      €
                    </Text>
                    <Text className="text-xs font-normal text-[#84818A] font-NunitoSans">
                      {i18n.t("profile.spend_required")}
                    </Text>
                    <Text className="text-[10px] font-light text-[#84818A] font-NunitoSans">
                      {i18n.t("profile.to_upgrade_to")}
                      {nextTier(member.tier)}
                    </Text>
                  </View>
                )}
              </View>

              {/* Spend required to maintain Diamond tier membership before next cycle*/}
              {member.tier == "Diamond" ? (
                <View className="hidden w-1/2 mt-2">
                  <Text className="text-xl font-semibold text-black font-Crimson">
                    {/* S${currencyFormat(outstandingAmt)} */}
                    {outstandingAmt}€
                  </Text>
                  <Text className="text-[10px] font-light text-[#84818A] font-NunitoSans">
                    {i18n.t("profile.nett_spend_required")}
                    {currentTier(member.tier)}
                  </Text>
                </View>
              ) : (
                <View className="hidden"></View>
              )}

              {/* Spend required to upgrade to Diamond Tier from current tier membership */}
              <View className="hidden w-1/2 mt-2">
                {member.tier != "Gold" && member.tier != "Diamond" && (
                  <View>
                    <Text className="text-xl font-semibold text-black font-Crimson">
                      {currencyFormat(osAmtToDiamond)}€
                    </Text>
                    <Text className="text-[10px] font-light text-[#84818A] font-NunitoSans">
                      {i18n.t("profile.nett")}{" "}
                      {i18n.t("profile.spend_required")}{" "}
                      {i18n.t("profile.to_upgrade_to")}
                      Diamond Membership
                    </Text>
                  </View>
                )}

                {/* {member.tier == "Diamond" ? (
                  <View className="hidden">
                    <Text className="text-xl font-semibold text-black font-Crimson">
                      S$
                      {osAmtToDiamond}
                    </Text>
                    <Text className="text-xs font-normal text-[#84818A] font-NunitoSans">
                      Nett {i18n.t("profile.spend_required")} to maintain
                      Diamond Membership
                    </Text>
                  </View>
                ) : (
                  <View>
                    <Text className="text-xl font-semibold text-black font-Crimson">
                      S$
                      {currencyFormat(osAmtToDiamond)}
                    </Text>
                    <Text className="text-[10px] font-light text-[#84818A] font-NunitoSans">
                      Nett {i18n.t("profile.spend_required")} to upgrade to
                      Diamond Membership
                    </Text>
                  </View>
                )} */}
              </View>
            </View>
          </View>

          {/* i-Dollar Area */}
          <View className="w-full md:w-[32%] h-auto bg-white p-5 min-h-[170px] mb-2 md:mb-0">
            <View className="flex flex-row flex-wrap items-center justify-start">
              <Text className="text-xl font-semibold text-black font-Crimson mr-1">
                {i18n.t("profile.i_dollar")}
              </Text>
              <Text className="text-sm font-semibold text-[#86909C] font-NunitoSans">
                {i18n.t("profile.last_update")}
                {dateFormat(pointsRecord.last_update)}
              </Text>
            </View>

            <View className="flex flex-row flex-wrap items-center justify-between mt-6">
              <View class="w-[33%] text-center">
                <Text className="text-xl font-semibold text-black font-Crimson text-center">
                  {pointsRecord.earned}
                </Text>
                <Text className="text-xs font-normal text-[#84818A] font-NunitoSans text-center">
                  {i18n.t("profile.i_dollar_earned")}
                </Text>
              </View>
              <View class="w-[33%] text-center">
                <Text className="text-xl font-semibold text-black font-Crimson text-center">
                  {pointsRecord.converted}
                </Text>
                <Text className="text-xs font-normal text-[#84818A] font-NunitoSans text-center">
                  {i18n.t("profile.i_dollar_converted")}
                </Text>
              </View>
              <View class="w-[33%] text-center">
                <Text className="text-xl font-semibold text-black font-Crimson text-center">
                  {pointsRecord.balance}
                </Text>
                <Text className="text-xs font-normal text-[#84818A] font-NunitoSans text-center">
                  {i18n.t("profile.i_dollar_balance")}
                </Text>
              </View>
            </View>

            <View className="mt-5">
              <Text className="text-[10px] font-light text-[#84818A] font-NunitoSans">
                {i18n.t("profile.i_dollar_automatically")}
              </Text>
            </View>
          </View>

          {/* Vouchers Area */}
          <View className="w-full md:w-[32%] h-auto bg-white p-5">
            <View className="flex flex-row flex-wrap items-center justify-start">
              <Text className="text-xl font-semibold text-black font-Crimson mr-1">
                {i18n.t("profile.vouchers")}
              </Text>
              <Text className="text-sm font-semibold text-[#86909C] font-NunitoSans">
                {" "}
                {couponStatus.issued} {i18n.t("profile.currently_active")}
              </Text>
            </View>

            <View className="flex flex-row lg:flex-row flex-wrap items-start lg:items-center justify-between">
              <TouchableOpacity
                className="w-1/2"
                onPress={() => goCouponPage()}
              >
                <View className="flex flex-row items-start justify-start mt-5">
                  <View>
                    <Image
                      className="mr-1 w-6 h-6"
                      source={require("../assets/account_balance_wallet.png")}
                    />
                  </View>
                  <View>
                    <Text className="text-xl font-semibold text-black font-Crimson">
                      {i18n.t("profile.vouchers")}
                    </Text>
                    <Text className="text-xs font-normal text-[#84818A] font-NunitoSans">
                      {couponCount.cash} {i18n.t("profile.cash_vouchers")}
                    </Text>
                    <Text className="text-xs font-normal text-[#84818A] font-NunitoSans">
                      {couponCount.food} {i18n.t("profile.food_vouchers")}
                    </Text>
                  </View>
                </View>
              </TouchableOpacity>

              <TouchableOpacity
                className="w-auto max-w-[150px] h-8 max-h-8 bg-brandgold mr-2 px-4 mt-5"
                onPress={() => goCouponPage()}
              >
                <Text className="text-white w-full h-8 leading-8 text-center font-normal font-PingFangSC">
                  {i18n.t("profile.click_to_view")}
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>

        {/* Transaction Record Area */}
        <View className="hidden md:flex">
          <TransactionDetails />
        </View>

        <View className="w-full md:w-4/5 max-w-7xl pl-4 pr-4 pt-2 pb-2 md:p-0 m-auto md:mt-3 md:hidden">
          <View className="bg-white p-4">
            <View className="flex md:flex-row flex-row items-center justify-between md:mb-6">
              <View>
                <Text className="text-xl font-semibold text-black font-Crimson">
                  {i18n.t("profile.transaction")}
                </Text>
              </View>

              {/* Mobile display - View button */}
              <TouchableOpacity
                className="w-auto h-8 max-h-8 bg-brandgold mt-1 mr-3 px-4 "
                onPress={() => {
                  navigation.push("Transaction");
                }}
              >
                <Text className="text-white w-full h-8 leading-8 text-center font-PingFangSC">
                  {i18n.t("profile.view_more")}
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </ScrollView>

      {renewOverlay && (
        <View
          className="top-0 right-0 bottom-0 left-0 justify-center items-center bg-[#00000075]"
          style={{ position: "fixed" }}
        >
          <View className="w-11/12 max-w-lg min-h-[300px] bg-white px-6 py-3 relative">
            <TouchableOpacity
              className="absolute right-1 top-1"
              onPress={() => setRenewOverlay(false)}
            >
              <Image
                className="w-5 h-5"
                source={require("../assets/btn-close.png")}
              />
            </TouchableOpacity>

            <View className="">
              <Text className="text-xl font-semibold text-[#111111] text-center py-3 uppercase font-Crimson">
                {i18n.t("profile.renew_membership")}
              </Text>
              <View className="w-full h-[0.5px] bg-brandgold mt-3"></View>
              <Text className="text-base font-medium pt-6 pb-4">
                {i18n.t("profile.renew_membership_1")}
              </Text>
              <Text className="text-sm font-normal text-[#111111]">
                - {i18n.t("profile.renew_membership_2")}
              </Text>
              <Text className="text-sm font-normal text-[#111111]">
                - {i18n.t("profile.renew_membership_3")}
              </Text>
              <Text className="text-sm font-normal text-[#111111]">
                - {i18n.t("profile.renew_membership_4")}
              </Text>
              <View className="w-full h-[0.5px] bg-brandgold mt-3"></View>
              <View className="text-right flex items-end">
                <TouchableOpacity
                  className="w-[162px] h-8 max-h-8 bg-brandgold mt-4"
                  onPress={() => confirmActive()}
                >
                  <Text className="text-white w-full h-8 leading-8 text-center font-normal font-PingFangSC">
                    {i18n.t("profile.confirm_to_renew")}
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </View>
      )}

      {activiateOverlay && (
        <View
          className="top-0 right-0 bottom-0 left-0 justify-center items-center bg-[#00000075]"
          style={{ position: "fixed" }}
        >
          <View className="w-11/12 max-w-lg min-h-[300px] bg-white px-6 py-3 relative">
            <TouchableOpacity
              className="absolute right-1 top-1"
              onPress={() => setActiviateOverlay(false)}
            >
              <Image
                className="w-5 h-5"
                source={require("../assets/btn-close.png")}
              />
            </TouchableOpacity>

            {memberStatus == "active" && (
              <View className="">
                <Text className="text-xl font-semibold text-[#111111] text-center py-3 uppercase font-Crimson">
                  {i18n.t("profile.renew_membership")}
                </Text>
                <View className="w-full h-[0.5px] bg-brandgold mt-3"></View>
                <Text className="text-base font-medium pt-6 pb-4">
                  {i18n.t("profile.renew_membership_1")}
                </Text>
                <Text className="text-sm font-normal text-[#111111]">
                  {i18n.t("profile.renew_membership_2")}
                </Text>
                <Text className="text-sm font-normal text-[#111111]">
                  {i18n.t("profile.renew_membership_3")}
                </Text>
                <Text className="text-sm font-normal text-[#111111]">
                  {i18n.t("profile.renew_membership_4")}
                </Text>
                <View className="w-full h-[0.5px] bg-brandgold mt-3"></View>
                <View className="text-right flex items-end">
                  <TouchableOpacity
                    className="w-[162px] h-8 max-h-8 bg-brandgold mt-4"
                    onPress={() => confirmActive()}
                  >
                    <Text className="text-white w-full h-8 leading-8 text-center font-normal font-PingFangSC">
                      {i18n.t("profile.confirm_to_renew")}
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            )}

            {memberStatus == "expired" && (
              <View className="">
                <Text className="text-xl font-semibold text-[#111111] text-center py-3 uppercase font-Crimson">
                  {i18n.t("profile.activate_membership")}
                </Text>
                <View className="w-full h-[0.5px] bg-brandgold mt-3"></View>
                <Text className="text-base font-medium pt-6 pb-4">
                  {i18n.t("profile.activate_membership_1")}
                </Text>
                <Text className="text-sm font-normal text-[#111111]">
                  - {i18n.t("profile.activate_membership_2")}
                </Text>
                <Text className="text-sm font-normal text-[#111111]">
                  - {i18n.t("profile.activate_membership_3")}
                </Text>
                <Text className="text-sm font-normal text-[#111111]">
                  - {i18n.t("profile.activate_membership_4")}
                </Text>
                <Text className="text-sm font-normal text-[#111111]">
                  - {i18n.t("profile.activate_membership_5")}
                </Text>
                <Text className="text-sm font-normal text-[#111111]">
                  - {i18n.t("profile.activate_membership_6")}
                </Text>
                <Text className="text-sm font-normal text-[#111111]">
                  - {i18n.t("profile.activate_membership_7")}
                </Text>
                <View className="w-full h-[0.5px] bg-brandgold mt-3"></View>
                <View className="text-right flex items-end">
                  <TouchableOpacity
                    className="w-[162px] h-8 max-h-8 bg-brandgold mt-4"
                    onPress={() => confirmActive()}
                  >
                    <Text className="text-white w-full h-8 leading-8 text-center font-normal font-PingFangSC">
                      {i18n.t("profile.confirm_to_activate")}
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            )}
          </View>
        </View>
      )}

      {passwordOverlay && (
        <View
          className="top-0 right-0 bottom-0 left-0 justify-center items-center bg-[#00000075]"
          style={{ position: "fixed" }}
        >
          <View className="w-11/12 max-w-lg min-h-[300px] bg-white px-6 py-3 relative">
            <TouchableOpacity
              className="absolute right-1 top-1"
              onPress={() => setPasswordOverlay(false)}
            >
              <Image
                className="w-5 h-5"
                source={require("../assets/btn-close.png")}
              />
            </TouchableOpacity>

            <Text className="text-xl font-semibold text-[#111111] text-center py-3 uppercase font-Crimson">
              {i18n.t("profile.change_password")}
            </Text>
            <View className="w-full h-[0.5px] bg-brandgold mt-3"></View>

            <Text className="text-base font-medium pt-6 pb-4">
              {i18n.t("profile.previous_password")}
            </Text>
            <TextInput
              placeholder=""
              className="text-[#86909C] h-8 w-full border border-[#F2F3F5] bg-[#F2F3F5] px-4"
              secureTextEntry={true}
              value={pPassword}
              onChangeText={setPPassword}
            ></TextInput>

            {pPasswordErr ? (
              <Text className="color-red-500 my-1">
                {i18n.t("profile.previous_password_errMsg")}
              </Text>
            ) : null}

            <Text className="text-base font-medium pt-6 pb-4">
              {i18n.t("profile.new_password")}
            </Text>
            <TextInput
              placeholder=""
              className="text-[#86909C] h-8 w-full border border-[#F2F3F5] bg-[#F2F3F5] px-4"
              secureTextEntry={true}
              value={password}
              onChangeText={setPassword}
            ></TextInput>

            {passwordFormatErr && (
              <Text className="color-red-500 my-1">
                {i18n.t("profile.new_password_errMsg2")}
              </Text>
            )}

            <Text className="text-base font-medium pt-6 pb-4">
              {i18n.t("profile.confirm_password")}
            </Text>
            <TextInput
              placeholder=""
              className="text-[#86909C] h-8 w-full border border-[#F2F3F5] bg-[#F2F3F5] px-4"
              secureTextEntry={true}
              value={confirmPassword}
              onChangeText={setConfirmPassword}
            ></TextInput>

            {updatePasswordErr ? (
              <Text className="color-red-500 my-1">
                {i18n.t("profile.new_password_errMsg1")}
              </Text>
            ) : null}

            <View className="w-full h-[0.5px] bg-brandgold mt-3"></View>
            <View className="text-right flex items-end">
              <TouchableOpacity
                className="w-[162px] h-8 max-h-8 bg-brandgold mt-4"
                onPress={() => confirmUpdatePassword()}
              >
                <Text className="text-white w-full h-8 leading-8 text-center font-normal font-PingFangSC">
                  {i18n.t("profile.change")}
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      )}

      <Footer />
      <CookieConsent />

      <Modal animationType="slide" visible={paymentWebview}>
        <TouchableOpacity
          onPress={() => {
            setPaymentWebview(false);
          }}
          style={{ marginVertical: 15, alignItems: "center" }}
        >
          <Text>{i18n.t("profile.close")}</Text>
        </TouchableOpacity>

        <WebView
          source={{ uri: paymentURL }}
          style={{ marginTop: 0 }}
          onLoad={(syntheticEvent) => {
            const { nativeEvent } = syntheticEvent;
            console.log(nativeEvent);
            //this.url = nativeEvent.url;
          }}
          onNavigationStateChange={(navState) => {
            console.log("navState :", navState);
          }}
          scalesPageToFit={false}
          onMessage={(event) => {
            console.log(event);

            if (event.nativeEvent.data.paymentResult) {
              let resCode = event.nativeEvent.data.paymentResult.respCode;
              console.log("resCode :", resCode);

              if (resCode == "2000") {
                setIsLoading(true);

                setTimeout(() => {
                  setIsLoading(false);
                  setActiviateOverlay(false);
                  setPaymentWebview(false);
                  CheckPayment();
                  //submitForm();
                  console.log("Payment success");
                }, 3000);
              }
            }
          }}
        />
      </Modal>

      {snackbarActiviated && (
        <View
          className="bg-white right-5 top-11 min-w-[200px] h-10 border border-[#E5E6EB] flex flex-row justify-around items-center"
          style={{ position: "fixed" }}
        >
          <Image
            className="w-5 h-5"
            source={require("../assets/icons/icon-green-tick.png")}
          />
          <Text>{i18n.t("profile.member_activated")}</Text>
          <TouchableOpacity onPress={() => setSnackbarActiviated(false)}>
            <Image
              className="w-5 h-5"
              source={require("../assets/btn-close.png")}
            />
          </TouchableOpacity>
        </View>
      )}

      {snackbarPasswordUpdated && (
        <View
          className="bg-white right-5 top-11 min-w-[200px] h-10 border border-[#E5E6EB] flex flex-row justify-around items-center"
          style={{ position: "fixed" }}
        >
          <Image
            className="w-5 h-5"
            source={require("../assets/icons/icon-green-tick.png")}
          />
          <Text>{i18n.t("profile.password_updated")}</Text>
          <TouchableOpacity onPress={() => setSnackbarPasswordUpdated(false)}>
            <Image
              className="w-5 h-5"
              source={require("../assets/btn-close.png")}
            />
          </TouchableOpacity>
        </View>
      )}
    </SafeAreaView>
  );
};

export default Profile;
