import * as Localization from "expo-localization";
import { I18n } from "i18n-js";
import AsyncStorage from "../components/AsyncStorage";

import en from "../locale/en";
import fr from "../locale/fr";

const translations = {
  en: en,
  fr: fr,
};

const i18n = new I18n(translations);

AsyncStorage.getData("lang").then((value) => {
  i18n.locale = value || "fr";
  // if (!value) {
  //   console.log(Localization.locale);
  //   i18n.locale = Localization.locale;
  // } else {
  //   // console.log(value);
  //   i18n.locale = value;
  // }
});

i18n.enableFallback = true;

export default i18n;
