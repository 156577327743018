import React from "react";
import {
  View,
  Text,
  ImageBackground,
  SafeAreaView,
  ScrollView,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import MobileHeader from "../components/mHeader";
import Footer from "../components/footer";
import Header from "../components/header";
import i18n from "../components/i18n";
import CookieConsent from "../components/CookieConsent";
import NewMobileHeader from "../components/newMobileHeader";

const Tnc = () => {
  const navigation = useNavigation();

  return (
    <View className="md:bg-[#efefef]">
      <ImageBackground
        source={require("../assets/login-bg.png")}
        resizeMode="cover"
        className="flex-1 justify-start items-center w-full h-full"
      >
        <SafeAreaView className="bg-white flex justify-start items-center w-full md:bg-transparent">
          <Header />
          <NewMobileHeader />
          <MobileHeader title={i18n.t("tnc.title")} />
          <View className="w-11/12 md:w-4/5 md:max-w-screen-xl md:h-auto md:m-auto md:bg-white md:p-7 mt-6 md:mb-24 pb-10">
            {/* Consent Form Area */}
            <View className="items-center justify-center hidden">
              <ScrollView horizontal={true}>
                <View className="mt-2 mb-4 max-w-[750px] overflow-hidden overflow-x-scroll">
                  <View className="mt-1">
                    <View className="flex flex-col bg-white">
                      <Text className="w-full border border-black border-b-0 px-4 py-2 text-sm font-semibold">
                        {i18n.t("tnc.consent")}
                      </Text>
                      <Text className="w-full border border-black border-t-0 border-b-0 px-4 py-2 text-sm">
                        {i18n.t("tnc.consent_1_1")}
                      </Text>
                      <Text className="w-full border border-black border-t-0 px-4 py-2 text-sm">
                        {i18n.t("tnc.consent_1_2")}
                      </Text>
                    </View>
                    <View className="flex flex-col bg-white">
                      <Text className="w-full border border-black border-t-0 border-b-0 px-4 py-3 text-sm font-semibold">
                        {i18n.t("tnc.consent_opt")}
                      </Text>

                      <Text className="w-full border border-black border-t-0 border-b-0 px-4 py-2 text-sm">
                        {i18n.t("tnc.consent_2_1")}
                      </Text>
                      <Text className="w-full border border-black border-t-0 border-b-0 px-4 py-2 text-sm">
                        {i18n.t("tnc.consent_2_2")}
                      </Text>
                      <Text className="w-full border border-black border-t-0 px-4 py-2 text-sm">
                        {i18n.t("tnc.consent_2_3")}
                      </Text>
                    </View>
                  </View>
                </View>
              </ScrollView>
            </View>

            <Text className="text-black text-2xl mt-6 mb-4 uppercase font-Crimson md:text-center">
              {i18n.t("tnc.membership")}
            </Text>

            <View className="w-full h-[0.5px] bg-brandgold mt-3"></View>

            <Text className="text-sm text-[#494949] mt-6 mb-1 font-semibold">
              {i18n.t("tnc.membership_1_1")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.membership_1_1_1")}
            </Text>

            <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">
              {i18n.t("tnc.membership_1_2")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.membership_1_2_1")}
            </Text>

            <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">
              {i18n.t("tnc.membership_1_3")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.membership_1_3_1")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.membership_1_3_2")}
            </Text>

            <Text className="text-sm text-[#494949] mt-1 mb-6 font-semibold">
              {i18n.t("tnc.membership_1_4")}
            </Text>

            <Text className="text-sm text-[#494949] ml-10 mt-1 mb-1 font-semibold">
              {i18n.t("tnc.membership_1_4_1")}
            </Text>
            <Text className="text-sm text-[#494949] ml-10 mt-1 mb-2">
              {i18n.t("tnc.membership_1_4_1_1")}
            </Text>
            <Text className="text-sm text-[#494949] ml-10 mt-1 mb-2">
              {i18n.t("tnc.membership_1_4_1_2")}
            </Text>
            <Text className="text-sm text-[#494949] ml-10 mt-1 mb-2">
              {i18n.t("tnc.membership_1_4_1_3")}
            </Text>
            <Text className="text-sm text-[#494949] ml-10 mt-1 mb-6">
              {i18n.t("tnc.membership_1_4_1_4")}
            </Text>

            <Text className="text-sm text-[#494949] ml-10 mt-1 mb-1 font-semibold">
              {i18n.t("tnc.membership_1_4_2")}
            </Text>
            <Text className="text-sm text-[#494949] ml-10 mt-1 mb-2">
              {i18n.t("tnc.membership_1_4_2_1")}
            </Text>
            <Text className="text-sm text-[#494949] ml-10 mt-1 mb-2">
              {i18n.t("tnc.membership_1_4_2_2")}
            </Text>
            <Text className="text-sm text-[#494949] ml-10 mt-1 mb-2">
              {i18n.t("tnc.membership_1_4_2_3")}
            </Text>
            <Text className="text-sm text-[#494949] ml-10 mt-1 mb-6 ">
              {i18n.t("tnc.membership_1_4_2_4")}
            </Text>

            <Text className="text-sm text-[#494949] ml-10 mt-1 mb-1 font-semibold">
              {i18n.t("tnc.membership_1_4_3")}
            </Text>
            <Text className="text-sm text-[#494949] ml-10 mt-1 mb-6">
              {i18n.t("tnc.membership_1_4_3_1")}
            </Text>

            <Text className="text-sm text-[#494949] ml-10 mt-1 mb-1 font-semibold">
              {i18n.t("tnc.membership_1_4_4")}
            </Text>
            <Text className="text-sm text-[#494949] ml-10 mt-1 mb-6">
              {i18n.t("tnc.membership_1_4_4_1")}
            </Text>
            <Text className="text-sm text-[#494949] ml-10 mt-1 mb-6">
              {i18n.t("tnc.membership_1_4_4_2")}
            </Text>

            <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">
              {i18n.t("tnc.membership_1_5")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.membership_1_5_1")}
            </Text>

            <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">
              {i18n.t("tnc.membership_1_6")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.membership_1_6_1")}
            </Text>

            <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">
              {i18n.t("tnc.membership_2")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.membership_2_1")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.membership_2_2")}
            </Text>

            <Text className="text-black text-2xl mt-6 mb-4 uppercase font-Crimson md:text-center">
              {i18n.t("tnc.privacy")}
            </Text>

            <View className="w-full h-[0.5px] bg-brandgold mt-3"></View>

            <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">
              {i18n.t("tnc.privacy_1")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_1_1")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-2 ">
              {i18n.t("tnc.privacy_1_2")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-2 ">
              {i18n.t("tnc.privacy_1_3")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-2 ">
              {i18n.t("tnc.privacy_1_4")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_1_5")}
            </Text>

            <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">
              {i18n.t("tnc.privacy_2")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_2_1")}
            </Text>
            <View className="mb-2 ml-10 flex-row">
              <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
              <Text className="flex-1 pl-2 text-sm text-[#303030]">
                {i18n.t("tnc.privacy_2_1_1")}
              </Text>
            </View>
            <View className="mb-2 ml-10 flex-row">
              <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
              <Text className="flex-1 pl-2 text-sm text-[#303030]">
                {i18n.t("tnc.privacy_2_1_2")}
              </Text>
            </View>
            <View className="mb-2 ml-10 flex-row">
              <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
              <Text className="flex-1 pl-2 text-sm text-[#303030]">
                {i18n.t("tnc.privacy_2_1_3")}
              </Text>
            </View>
            <View className="mb-2 ml-10 flex-row">
              <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
              <Text className="flex-1 pl-2 text-sm text-[#303030]">
                {i18n.t("tnc.privacy_2_1_4")}
              </Text>
            </View>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_2_2")}
            </Text>
            <View className="mb-2 ml-10 flex-row">
              <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
              <Text className="flex-1 pl-2 text-sm text-[#303030]">
                {i18n.t("tnc.privacy_2_2_1")}
              </Text>
            </View>
            <View className="mb-2 ml-14 flex-row">
              <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
              <Text className="flex-1 pl-2 text-sm text-[#303030]">
                {i18n.t("tnc.privacy_2_2_1_1")}
              </Text>
            </View>
            <View className="mb-2 ml-14 flex-row">
              <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
              <Text className="flex-1 pl-2 text-sm text-[#303030]">
                {i18n.t("tnc.privacy_2_2_1_2")}
              </Text>
            </View>
            <View className="mb-2 ml-14 flex-row">
              <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
              <Text className="flex-1 pl-2 text-sm text-[#303030]">
                {i18n.t("tnc.privacy_2_2_1_3")}
              </Text>
            </View>
            <View className="mb-2 ml-14 flex-row">
              <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
              <Text className="flex-1 pl-2 text-sm text-[#303030]">
                {i18n.t("tnc.privacy_2_2_1_4")}
              </Text>
            </View>
            <View className="mb-2 ml-10 flex-row">
              <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
              <Text className="flex-1 pl-2 text-sm text-[#303030]">
                {i18n.t("tnc.privacy_2_2_2")}
              </Text>
            </View>
            <View className="mb-2 ml-14 flex-row">
              <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
              <Text className="flex-1 pl-2 text-sm text-[#303030]">
                {i18n.t("tnc.privacy_2_2_2_1")}
              </Text>
            </View>
            <View className="mb-2 ml-14 flex-row">
              <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
              <Text className="flex-1 pl-2 text-sm text-[#303030]">
                {i18n.t("tnc.privacy_2_2_2_2")}
              </Text>
            </View>
            <View className="mb-2 ml-14 flex-row">
              <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
              <Text className="flex-1 pl-2 text-sm text-[#303030]">
                {i18n.t("tnc.privacy_2_2_2_3")}
              </Text>
            </View>
            <View className="mb-2 ml-14 flex-row">
              <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
              <Text className="flex-1 pl-2 text-sm text-[#303030]">
                {i18n.t("tnc.privacy_2_2_2_4")}
              </Text>
            </View>
            <View className="mb-2 ml-10 flex-row">
              <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
              <Text className="flex-1 pl-2 text-sm text-[#303030]">
                {i18n.t("tnc.privacy_2_2_3")}
              </Text>
            </View>
            <View className="mb-2 ml-14 flex-row">
              <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
              <Text className="flex-1 pl-2 text-sm text-[#303030]">
                {i18n.t("tnc.privacy_2_2_3_1")}
              </Text>
            </View>
            <View className="mb-2 ml-14 flex-row">
              <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
              <Text className="flex-1 pl-2 text-sm text-[#303030]">
                {i18n.t("tnc.privacy_2_2_3_2")}
              </Text>
            </View>
            <View className="mb-2 ml-10 flex-row">
              <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
              <Text className="flex-1 pl-2 text-sm text-[#303030]">
                {i18n.t("tnc.privacy_2_2_4")}
              </Text>
            </View>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_2_3")}
            </Text>

            <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">
              {i18n.t("tnc.privacy_3")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_3_1")}
            </Text>

            <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">
              {i18n.t("tnc.privacy_3_1_1")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-2 ">
              {i18n.t("tnc.privacy_3_1_1_1")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_3_1_1_2")}
            </Text>

            <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">
              {i18n.t("tnc.privacy_3_1_2")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-3 ">
              {i18n.t("tnc.privacy_3_1_2_1")}
            </Text>

            <View className="mb-2 ml-10 flex-row">
              <View className="w-[28%] md:w-[20%] flex flex-row">
                <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
                <Text className="flex-1 pl-2 text-sm text-[#303030] font-semibold">
                  {i18n.t("tnc.privacy_3_1_3")}
                </Text>
              </View>
              <View className="w-[72%] md:w-[80%]">
                <Text className="flex-1 pl-2 text-sm text-[#303030]">
                  {i18n.t("tnc.privacy_3_1_3_1")}
                </Text>
              </View>
            </View>

            <View className="mb-2 ml-10 flex-row">
              <View className="w-[28%] md:w-[20%] flex flex-row">
                <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
                <Text className="flex-1 pl-2 text-sm text-[#303030] font-semibold">
                  {i18n.t("tnc.privacy_3_1_4")}
                </Text>
              </View>
              <View className="w-[72%] md:w-[80%]">
                <Text className="flex-1 pl-2 text-sm text-[#303030]">
                  {i18n.t("tnc.privacy_3_1_4_1")}
                </Text>
              </View>
            </View>

            <View className="mb-2 ml-10 flex-row">
              <View className="w-[28%] md:w-[20%] flex flex-row">
                <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
                <Text className="flex-1 pl-2 text-sm text-[#303030] font-semibold">
                  {i18n.t("tnc.privacy_3_1_5")}
                </Text>
              </View>
              <View className="w-[72%] md:w-[80%]">
                <Text className="flex-1 pl-2 text-sm text-[#303030]">
                  {i18n.t("tnc.privacy_3_1_5_1")}
                </Text>
              </View>
            </View>

            <View className="mb-2 ml-10 flex-row">
              <View className="w-[28%] md:w-[20%] flex flex-row">
                <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
                <Text className="flex-1 pl-2 text-sm text-[#303030] font-semibold">
                  {i18n.t("tnc.privacy_3_1_6")}
                </Text>
              </View>
              <View className="w-[72%] md:w-[80%]">
                <Text className="flex-1 pl-2 text-sm text-[#303030]">
                  {i18n.t("tnc.privacy_3_1_6_1")}
                </Text>
              </View>
            </View>

            <View className="mb-2 ml-10 flex-row">
              <View className="w-[28%] md:w-[20%] flex flex-row">
                <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
                <Text className="flex-1 pl-2 text-sm text-[#303030] font-semibold">
                  {i18n.t("tnc.privacy_3_1_7")}
                </Text>
              </View>
              <View className="w-[72%] md:w-[80%]">
                <Text className="flex-1 pl-2 text-sm text-[#303030]">
                  {i18n.t("tnc.privacy_3_1_7_1")}
                </Text>
              </View>
            </View>

            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_3_1_8")}
            </Text>

            <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">
              {i18n.t("tnc.privacy_3_2")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_3_2_1")}
            </Text>

            <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">
              {i18n.t("tnc.privacy_3_3")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_3_3_1")}
            </Text>

            <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">
              {i18n.t("tnc.privacy_4")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_4_1")}
            </Text>

            <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">
              {i18n.t("tnc.privacy_5")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_5_1")}
            </Text>

            <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">
              {i18n.t("tnc.privacy_6")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_6_1")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_6_2")}
            </Text>

            <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">
              {i18n.t("tnc.privacy_7")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_7_1")}
            </Text>
            <View className="mb-2 ml-10 flex-row">
              <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
              <Text className="flex-1 pl-2 text-sm text-[#303030]">
                {i18n.t("tnc.privacy_7_1_1")}
              </Text>
            </View>
            <View className="mb-2 ml-10 flex-row">
              <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
              <Text className="flex-1 pl-2 text-sm text-[#303030]">
                {i18n.t("tnc.privacy_7_1_2")}
              </Text>
            </View>
            <View className="mb-2 ml-10 flex-row">
              <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
              <Text className="flex-1 pl-2 text-sm text-[#303030]">
                {i18n.t("tnc.privacy_7_1_3")}
              </Text>
            </View>
            <View className="mb-2 ml-10 flex-row">
              <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
              <Text className="flex-1 pl-2 text-sm text-[#303030]">
                {i18n.t("tnc.privacy_7_1_4")}
              </Text>
            </View>
            <View className="mb-2 ml-10 flex-row">
              <Text className="text-sm text-[#303030]">{"\u2022"}</Text>
              <Text className="flex-1 pl-2 text-sm text-[#303030]">
                {i18n.t("tnc.privacy_7_1_5")}
              </Text>
            </View>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_7_2")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_7_3")}
            </Text>

            <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">
              {i18n.t("tnc.privacy_8")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_8_1")}
            </Text>

            <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">
              {i18n.t("tnc.privacy_9")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_9_1")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_9_2")}
            </Text>

            <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">
              {i18n.t("tnc.privacy_10")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_10_1")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_10_2")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_10_3")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_10_4")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_10_5")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_10_6")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_10_7")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_10_8")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_10_9")}
            </Text>

            <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">
              {i18n.t("tnc.privacy_11")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_11_1")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_11_2")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_11_3")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_11_4")}
            </Text>

            <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">
              {i18n.t("tnc.privacy_12")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_12_1")}
            </Text>

            <Text className="text-sm text-[#494949] mt-1 mb-1 font-semibold">
              {i18n.t("tnc.privacy_13")}
            </Text>
            <Text className="text-sm text-[#494949] mt-1 mb-6 ">
              {i18n.t("tnc.privacy_13_1")}
            </Text>
          </View>

          <Footer />
          <CookieConsent />
        </SafeAreaView>
      </ImageBackground>
    </View>
  );
};

export default Tnc;
